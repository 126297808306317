const API_ENDPOINT =
  "https://vzjp5j0y8i.execute-api.us-east-1.amazonaws.com/files";

export const getBucketFileList = async () => {
  const response = await fetch(API_ENDPOINT);
  return await response.json();
};

export const deleteBucketFile = async (key) => {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };
  const response = await fetch(`${API_ENDPOINT}/${key}`, requestOptions);
  return await response.json();
};
