import React from "react";
import "./ProfilePage.css";
import { saveHoloUser } from "../../../Connector/UserApiConnector";
import Workspace from "../Layout/Workspace/Workspace";
import ProfileEditForm from "../ProfileEditForm/ProfileEditForm";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../useAuth";
import { Pages } from "../../../constants";
import SignInPage from "../../SignInPage/SignInPage";

const ProfilePage = () => {
  const navigate = useNavigate();
  const authenticator = useAuth();

  const saveUserProfile = async (user) => {
    await saveHoloUser({ ...user, username: authenticator.username });
    navigate(Pages.BROWSER_PAGE);
  };
  if (!authenticator.username) return <SignInPage />;
  if (!authenticator.profile) return <em>Loading...</em>;
  return (
    <div className="ProfilePage">
      {" "}
      <Workspace
        username={
          authenticator.profile?.firstName ? authenticator.username : ""
        }
        title="Edit your profile"
        primary="Complete your profile to use the Hologram Generator."
        padded
      >
        <ProfileEditForm
          onForm={() => navigate(Pages.BROWSER_PAGE)}
          onSave={saveUserProfile}
          email={authenticator.email}
          phone_number={authenticator.phone_number}
          {...authenticator.profile}
        />
      </Workspace>
    </div>
  );
};

ProfilePage.defaultProps = {};
export default ProfilePage;
