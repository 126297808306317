import { Typography } from "@mui/material";
import React from "react";
import { Media } from "../../Library";
import "./LoadingPanel.css";

const name =
  "edited/SYNERGENT - Background Video Loop 5 (Online Video-Marketing für Webseiten SEO Universal Search)-IBHQOoWVQHo.mp4#t=8,30";

const LoadingPanel = ({ variant = "h3", children = "Loading..." }) => {
  const args = { name };
  return (
    <div className="LoadingPanel">
      <div className="caption flex center middle debug">
        <Typography variant={variant} style={{ color: "white" }}>
          {children}
        </Typography>
      </div>
      <Media {...args} />
    </div>
  );
};

LoadingPanel.defaultProps = {};
export default LoadingPanel;
