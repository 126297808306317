import React from "react";
import { Auth } from "aws-amplify";
import {
  Button,
  Fab,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import "./Control.css";
import useAuth from "../../../useAuth";
import { mmss } from "../../../util";

const LabelBox = styled("fieldset")`
  width: 95%;
  min-width: 95%;
  padding-right: 24px;
  background-color: var(--color);
  border: solid 1px var(--color);
  border-radius: 5px;
  margin-bottom: 24px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  & legend {
    min-width: 180px;
    display: flex;
    align-items: center;
    border: solid 1px var(--color);
    background-color: var(--color);
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding: 12px;
    & p {
      margin: 0 24px;
    }
  }
`;

const EasyList = styled("ul")`
  list-style: none;
  padding: 0;
  margin: 0;
  & li {
    padding: 0 8px;
    border-bottom: solid 1px white;
    float: left;
    width: calc(24% - 16px);
  }
`;

const CommonButton = styled("IconButton")({
  color: "darkslategray",
  backgroundColor: "aliceblue",
  padding: 8,
  margin: "0 4px",
  borderRadius: "50%",
  boxShadow: "0 5px 13px -3px #333",
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <Fab {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const MenuNode = styled((props) => {
  const { selected, children, ...other } = props;
  return <MenuItem {...other}>{children}</MenuItem>;
})(({ theme, selected }) => ({
  fontWeight: !selected ? 400 : 600,
}));

/**
 *               <FormControlLabel
                control={
                  <Switch
                    checked={!sound}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Mute audio"
              />
 */
const BasicSwitch = (props) => {
  const { checked, label, onChange } = props;
  const handleChange = (event) => {
    !!onChange && onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label={label}
    />
  );
};

const SimpleMenu = ({ options, value, icon, onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="HoloOrderList">
      <IconButton
        aria-label="settings"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {icon}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((option, i) => (
          <MenuNode
            selected={i === value}
            key={i}
            onClick={() => {
              onClick(i);
              handleClose();
            }}
          >
            {option}
          </MenuNode>
        ))}
      </Menu>
    </div>
  );
};

const FeatureLogin = ({ setUser, children, onCancel }) => {
  const auth = useAuth();
  const [psw, setPsw] = React.useState("");
  const checkAuth = async () => {
    try {
      const user = await Auth.signIn(auth.username, psw);
      setUser(user);
    } catch (error) {
      alert("There was a problem with your login");
    }
  };
  return (
    <Stack>
      <Typography style={{ margin: 12 }}>{children}</Typography>
      <TextField
        type="password"
        value={psw}
        onChange={(e) => setPsw(e.target.value)}
      />
      <Button style={{ marginTop: 12 }} variant="contained" onClick={checkAuth}>
        verify
      </Button>
      <Button style={{ marginTop: 12 }} variant="outlined" onClick={onCancel}>
        cancel
      </Button>
    </Stack>
  );
};

const ssmm = (t) => {
  const s = t.split(":");
  return parseInt(s[1]) + s[0] * 60;
};

const Key = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
};

const TimeField = ({ time, onChange, ticks = 5, ...props }) => {
  const progress = mmss(time);
  const keyup = (e) => {
    const { keyCode } = e;
    keyCode === Key.DOWN && onChange(time - ticks);
    keyCode === Key.UP && onChange(time + ticks);
    console.log({ keyCode });
  };
  const change = (e) => {
    const { value } = e.target;
    const digits = ssmm(value);
    onChange(digits);
  };
  const args = {
    value: progress,
    onChange: change,
    onKeyUp: keyup,
  };
  return (
    <>
      <TextField {...props} {...args} />
    </>
  );
};

const TextBox = ({ value, setValue, onChange, ...props }) => {
  const keyup = (e) => {
    const { keyCode } = e;
    keyCode === Key.ENTER && onChange(value);
  };
  const change = (e) => {
    setValue(e.target.value);
  };
  const args = {
    value,
    onChange: change,
    onKeyUp: keyup,
  };
  return (
    <>
      <TextField {...props} {...args} />
    </>
  );
};

const Control = {
  CommonButton,
  ExpandMore,
  FeatureLogin,
  LabelBox,
  TimeField,
  EasyList,
  SimpleMenu,
  BasicSwitch,
  TextBox,
};
export default Control;
