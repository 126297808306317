import { Close, SettingsRemote } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import "./RemoteControlDialog.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RemoteControlDialog = (props) => {
  const { onNo, open, children } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  if (!matches) {
    return <RemoteControlDrawer {...props}>{children}</RemoteControlDrawer>;
  }
  const handleClose = () => {
    onNo();
  };
  return (
    <div className="RemoteControlDialog">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
            <Avatar sx={{ marginRight: "12px" }}>
              <SettingsRemote />
            </Avatar>
            Remote Control
          </Box>
        </DialogTitle>
        <DialogContent className="remote-content">{children}</DialogContent>
      </Dialog>
    </div>
  );
};

const RemoteControlDrawer = ({ onNo, open, children }) => {
  const handleClose = () => {
    onNo();
  };
  return (
    <div className="RemoteControlDialog">
      <Drawer
        classes={{ paper: "RemoteControlDialog" }}
        anchor="bottom"
        open={open}
        onClose={handleClose}
        style={{ minHeight: 300 }}
      >
        <Box
          className="flex"
          sx={{ display: "flex", alignItems: "center", pl: 2, pt: 1 }}
        >
          <Avatar sx={{ marginRight: "12px" }}>
            <SettingsRemote />
          </Avatar>
          <Typography variant="h6"> Remote Control</Typography>
          <div className="auto">
            <IconButton onClick={handleClose} style={{ marginRight: 24 }}>
              <Close />
            </IconButton>
          </div>
        </Box>
        <Box className="remote-content">{children}</Box>
      </Drawer>
    </div>
  );
};

RemoteControlDialog.defaultProps = {};
export default RemoteControlDialog;

export function useRemoteControlDialog() {
  const [state, setState] = React.useState({ open: false });
  const onNo = () => {
    setState((d) => ({ ...d, open: false }));
  };
  const show = () => {
    setState((s) => ({
      ...s,
      onNo,
      open: true,
    }));
  };
  return { state, show };
}
