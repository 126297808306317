import { Publish, Settings } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Collapse,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { uuid } from "../../util";
import Control from "../Common/Control/Control";
import "./VideoConfigSocket.css";

const SOCKET_URI =
  "wss://3zrs816956.execute-api.us-east-1.amazonaws.com/production";

let client = new WebSocket(SOCKET_URI);

const parseTube = (uri) => {
  // https://www.youtube.com/watch?v=E4QBSL4JGmc&list=PL_pN6g2WkNM2gZDqY6YU8VpbeAPHwNxEL&index=2
  const listReg = /list=(.{34})/;
  const tubeReg = /v=(.{11})/;
  const listKey = listReg.exec(uri);
  const tubeKey = tubeReg.exec(uri);
  if (listKey) return listKey[1];
  if (tubeKey) return tubeKey[1];
  alert("Could not parse " + uri);
};

class VideoConfigSocket extends React.Component {
  constructor(props) {
    super(props);
    const clientKey = localStorage.getItem("clientKey") || uuid();
    localStorage.setItem("clientKey", clientKey);
    this.state = {
      connected: false,
      ws: null,
      nodes: [],
      clientKey,
    };
    this.messageListener = this.messageListener.bind(this);
    this.closeListener = this.closeListener.bind(this);
    this.openListener = this.openListener.bind(this);
    this.retryConnection = this.retryConnection.bind(this);
  }

  errorListener(error) {
    alert("There was an error in the connection. Please check the console");
    console.log({ error });
  }

  closeListener() {
    console.log("You are disconnected.");
    this.setState({
      connected: false,
      ws: null,
      readyState: client.readyState,
    });
    if (this.state.logout) {
      return console.log("Reconnection will not be attempted");
    }
    // if (window.confirm("Reconnect?")) {
    this.retryConnection();
    // }
  }

  retryConnection() {
    setTimeout(() => {
      console.log("Retrying connection...");
      client = new WebSocket(SOCKET_URI);
      this.mountClient();
    }, 5000);
  }

  openListener() {
    console.log("You are connected.");
    this.setState({ readyState: client.readyState });
    this.sendClientStatus();
  }
  messageListener(message) {
    const { onComplete } = this.props;
    const { data } = message;
    console.log("Message received.", data);
    const json = JSON.parse(data);
    !!json.status && this.setState({ ...json, readyState: client.readyState });
    !!json.complete && onComplete && onComplete(json);
  }

  sendClientStatus(action = "introduce", args = {}) {
    const time = new Date().getTime();
    const nodes = this.props.nodes.map((node) => {
      const { preview, ...rest } = node;
      return rest;
    });
    const msg = {
      action,
      data: {
        time,
        nodes,
        ...args,
      },
    };
    console.log({ nodes, msg });
    this.sendMessage(msg);
  }
  attemptAction(fn) {
    try {
      fn();
    } catch (error) {
      console.log("unable to attempt action [[%s]]", fn.toString(), { error });
    }
  }

  componentDidUpdate() {
    const { nodes } = this.props;
    if (this.state.nodes?.length !== nodes?.length) {
      console.log(">>> Updating component <<<");
      this.setState({ nodes, readyState: client.readyState });
      this.sendClientStatus();
    }
  }

  sendMessage(json) {
    console.log("Sending message.");
    const { clientKey } = this.state;
    const states = ["CONNECTING", "OPEN", "CLOSING", "CLOSED"];
    const readyState = client.readyState;
    if (readyState === 1) {
      this.attemptAction(() =>
        client.send(JSON.stringify({ ...json, clientKey }))
      );
      this.setState({ readyState });
      return;
    }
    console.log(
      "unable to send message because the client is in %s state",
      states[readyState],
      { json }
    );
    //  readyState === 3 && this.retryConnection();
  }
  mountClient() {
    client.addEventListener("open", this.openListener);
    client.addEventListener("message", this.messageListener);
    client.addEventListener("close", this.closeListener);
    client.addEventListener("error", this.errorListener);
    this.sendClientStatus();
  }

  componentWillUnmount() {
    this.sendMessage({
      action: "disconnect",
    });
    this.setState({ logout: true, readyState: client.readyState });
  }

  componentDidMount() {
    this.mountClient();
  }

  render() {
    console.table(this.state);
    return (
      <SocketStatus
        {...this.state}
        connect={this.retryConnection}
        onClick={() => this.sendClientStatus("exec")}
        onTest={(e) => {
          const id = parseTube(e);
          this.sendClientStatus("exec", { youtube: !0, id });
        }}
      />
    );
  }
}

const PHOTO_ROOT = "https://s3.amazonaws.com/rubiks.miltonjones.nl";
const SocketStatus = ({
  status,
  progress,
  uploadProgress,
  working,
  nodes,
  onClick,
  onTest,
  readyState,
  photoProp,
  connect,
}) => {
  const [x, setX] = React.useState(false);
  const [u, setU] = React.useState("");
  if (readyState !== 1) {
    return (
      <>
        <Button
          onClick={() => connect && connect()}
          disabled={!connect}
          variant="contained"
        >
          connect!
        </Button>
      </>
    );
  }
  if (!nodes?.length && !working) {
    return (
      <Box className="flex center">
        <Collapse
          classes={{ root: "collapse" }}
          orientation="horizontal"
          in={!x}
        >
          <Alert severity="info">Video queue empty.</Alert>
        </Collapse>

        <Collapse
          classes={{ root: "collapse" }}
          orientation="horizontal"
          in={x}
        >
          <Control.TextBox
            value={u}
            label="Youtube URL"
            setValue={setU}
            onChange={onTest}
          />
          {/* <Button className="button" onClick={onTest} variant="contained">
            test me!! [{working?.toString()}]
          </Button> */}
        </Collapse>
        <IconButton
          className="button"
          onClick={() => setX(!x)}
          variant="contained"
        >
          <Publish />
        </IconButton>
      </Box>
    );
  }
  if (!!nodes?.length && !working) {
    return (
      <>
        <Badge badgeContent={nodes?.length}>
          <Button
            onClick={onClick}
            variant="contained"
            disabled={!nodes?.length}
          >
            export videos
          </Button>
        </Badge>
      </>
    );
  }
  return (
    <Box className="flex socket-status">
      {!!photoProp && (
        <Avatar
          variant="rounded"
          style={{ marginRight: 8 }}
          src={`${PHOTO_ROOT}${photoProp}`}
          alt={status}
        />
      )}
      <Stack>
        <Typography variant="caption">{status}</Typography>
        {!!progress && (
          <LinearProgress
            style={{ minWidth: 300 }}
            variant={progress > 0 ? "determinate" : "indeterminate"}
            value={progress}
          />
        )}
        {!!uploadProgress && (
          <>
            <Typography variant="caption">
              uploaded ({uploadProgress}%)
            </Typography>
            <LinearProgress
              style={{ minWidth: 300 }}
              variant="determinate"
              value={uploadProgress}
            />
          </>
        )}
      </Stack>
    </Box>
  );
};

VideoConfigSocket.defaultProps = {};
export default VideoConfigSocket;
