import React from "react";

const PHOTO_ROOT = "https://s3.amazonaws.com/rubiks.miltonjones.nl";
const API_ENDPOINT =
  "https://ksrw6op45j.execute-api.us-east-1.amazonaws.com/catalog";

const saveHoloType = async (data) => {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...data, degrees: data.degrees || "180" }),
  };
  const response = await fetch(API_ENDPOINT, requestOptions);
  return await response.json();
};

const deleteHoloType = async (type) => {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };
  const response = await fetch(`${API_ENDPOINT}/${type}`, requestOptions);
  return await response.json();
};

const getHoloType = async (type) => {
  const response = await fetch(`${API_ENDPOINT}/${type}`);
  return await response.json();
};

const getHoloTypes = async () => {
  const response = await fetch(API_ENDPOINT);
  return await response.json();
};

export function useCatalogConnector(degrees, username, readByHolo) {
  const [types, setTypes] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [tagList, setTagList] = React.useState([]);

  const c180 = React.useCallback(
    (d) => (n) => !n.degrees || parseInt(n.degrees) === parseInt(d),
    []
  );

  const c360 = React.useCallback(
    (d) => (n) => parseInt(n.degrees) === parseInt(d),
    []
  );

  const getCondition = React.useCallback(
    (deg) => (!deg || parseInt(deg) === 180 ? c180(deg) : c360(deg)),
    [c180, c360]
  );

  const degreeCollate = React.useCallback(
    (items) => {
      if (!degrees) return items;
      return items.filter(getCondition(degrees));
    },
    [degrees, getCondition]
  );

  const curate = React.useCallback(
    (list) =>
      list.map((i) => ({
        ...i,
        photo: `${PHOTO_ROOT}${i.photo.replace(PHOTO_ROOT, "")}`,
        name: i.name.replace(/-/g, " "),
      })),
    []
  );

  const updateTypes = React.useCallback(
    (itemList) => {
      const showAll = !!readByHolo || username === "admin";
      const condition = showAll
        ? (f) => f
        : (f) => f.tags?.indexOf("admin") < 0;
      const items = degreeCollate(itemList);
      const tagNames = ((z) => {
        items
          .filter((i) => !!i.tags)
          .map((i) => (z = z.concat(i.tags.split(","))));
        return Array.from(new Set(z)).sort((a, b) => (a > b ? 1 : -1));
      })([]);
      const someTags = showAll
        ? tagNames
        : tagNames.filter((c) => c !== "admin");
      setTags(someTags);
      setTagList(
        someTags.map((name) => ({
          name,
          count: items.filter((f) => f.tags?.indexOf(name) > -1).length,
        }))
      );

      const list = items.sort((a, b) => (a.name > b.name ? 1 : -1));
      const ret = list.filter(condition);
      setTypes(curate(ret));
    },
    [degreeCollate, username, readByHolo, curate]
  );

  const getTags = (tagNames) => {
    return types.filter((type) =>
      tagNames.some((f) => type.tags?.indexOf(f) > -1)
    );
  };

  const load = React.useCallback(async () => {
    const holoTypes = sessionStorage.getItem("holo-types");
    if (!holoTypes) {
      const data = await getHoloTypes();
      sessionStorage.setItem("holo-types", JSON.stringify(data?.Items));
      updateTypes(data?.Items);
      return;
    }
    updateTypes(JSON.parse(holoTypes));
  }, [updateTypes]);

  const reload = React.useCallback(async () => {
    sessionStorage.removeItem("holo-types");
    await load();
  }, [load]);

  const getType = React.useCallback(
    (n) => types.find((f) => f.type === n),
    [types]
  );

  const getTypeByVideo = React.useCallback(
    (n) => {
      console.log({ n, t: types.length, i: types[0] });
      return types.some((f) => !!f.video && f.video.indexOf(n) > -1);
    },
    [types]
  );

  const degreeUpdate = React.useCallback(
    async (type, degrees) => {
      const item = getType(type);
      if (!item) return alert("No item " + type);
      const res = await saveHoloType({ ...item, degrees });
      alert(res);
      await load();
    },
    [load, getType]
  );

  const update = React.useCallback(
    async (component, key, value) => {
      const updated = types.map((t) => {
        t.type === component.type && (t[key] = value);
        return t;
      });
      updateTypes(updated);
    },
    [types, updateTypes]
  );

  const save = React.useCallback(
    async (type) => {
      await saveHoloType(type);
      await load();
    },
    [load]
  );

  React.useEffect(() => {
    load();
  }, [load]);

  return {
    types,
    save,
    update,
    tags,
    tagList,
    getTags,
    getType,
    getCondition,
    degreeUpdate,
    getTypeByVideo,
    reload,
  };
}

export { saveHoloType, deleteHoloType, getHoloType, getHoloTypes };
