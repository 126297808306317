import { Alert, Button, Stack, Snackbar } from "@mui/material";
import React from "react";
import "./ConfirmAlertWell.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AlertDialogSlide({
  message,
  onYes,
  onNo,
  open,
  title = "Confirm",
  severity,
}) {
  const handleClose = () => {
    onNo();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onNo}> {!!onYes ? "No" : "Okay"}</Button>
          {!!onYes && <Button onClick={onYes}>Yes</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}

function CustomizedSnackbar({ message, onNo, open, severity }) {
  const handleClose = (event, reason) => {
    onNo && onNo();
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

const ConfirmAlertWell = (props) => {
  const { message, onYes, onNo, open, severity, dialog, snack } = props;
  if (snack) {
    return <CustomizedSnackbar {...props} />;
  }
  if (dialog) {
    return <AlertDialogSlide {...props} />;
  }
  if (!open) {
    return <i />;
  }
  return (
    <div className="ConfirmAlertWell">
      <Alert
        severity={severity}
        action={
          <>
            {!!onYes && (
              <Button
                className="button"
                color="inherit"
                size="small"
                variant="contained"
                onClick={onYes}
              >
                Yes
              </Button>
            )}
            <Button
              className="button"
              color="inherit"
              size="small"
              variant="outlined"
              onClick={onNo}
            >
              {!!onYes ? "No" : "Okay"}
            </Button>
          </>
        }
      >
        {message}
      </Alert>
    </div>
  );
};

export function useConfirmAlert() {
  const [state, setState] = React.useState(null);
  const onNo = () => {
    setState({ open: false, message: "OK THEN!" });
  };
  const say = (message, onYes) => {
    setState((s) => ({
      ...s,
      message,
      onNo,
      severity: "success",
      open: true,
    }));
  };
  const warn = (message) => {
    setState((s) => ({
      ...s,
      message,
      onNo,
      severity: "warning",
      open: true,
    }));
  };
  const ask = (message, onYes) => {
    setState((s) => ({
      ...s,
      message,
      onYes: () => {
        onNo();
        onYes();
      },
      onNo,
      severity: "info",
      open: true,
    }));
  };
  React.useEffect(() => {
    setState({ open: false });
  }, []);
  return { state, ask, say, warn };
}

ConfirmAlertWell.defaultProps = {};
export default ConfirmAlertWell;
