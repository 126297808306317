import React from "react";
import { getHoloPages } from "./Connector/HoloApiConnector";

export default function useUserPageData() {
  const [userPageData, setUserPageData] = React.useState({});
  const read = React.useCallback(async (name) => {
    const res = await getHoloPages();
    res.Items = res.Items.filter(
      (item) => item.username === name || (!item.username && name === "admin")
    );
    setUserPageData(res);
    return true;
  }, []);

  return { userPageData, read };
}
