export const css = (o) =>
  Object.keys(o)
    .filter((f) => !!o[f])
    .join(" ");

export function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const sortItems = (items) => {
  const list = items.map((o, i) => ({ ...o, order: o.order || i + 1 }));
  return list.sort((a, b) => (a.order > b.order ? 1 : -1));
};

export function copyText(text) {
  navigator.clipboard.writeText(text);

  alert("Copied the text: " + text);
}

export function getHostRoot() {
  const domain = window.location.hostname;
  const port = domain === "localhost" ? ":3000" : "";
  return `http://${domain + port}`;
}
export const collate = (collection, pageSize, page) => {
  const startPage = (page - 1) * pageSize;
  let truncated = collection?.slice(startPage, startPage + pageSize);
  const length = collection?.length;
  return {
    startPage,
    page,
    pageSize,
    truncated,
    length,
  };
};

const ten = (f) => (Math.abs(f) < 10 ? `0${Math.abs(f)}` : Math.abs(f));

export const mmss = (time) => {
  if (isNaN(time)) return "infinite";
  const ab = time > 0 ? "" : "-";
  const mm = ten(Math.floor(time / 60));
  const ss = ten((time % 60).toFixed(2));
  return `${ab}${mm}:${ss}`;
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
