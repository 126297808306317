import React from "react";
import { useCatalogConnector } from "../../Connector/CatalogApiConnector";
import useComponentState from "../../useComponentState";
import HoloPage from "../HoloPage/HoloPage";
import "./DemoPage.css";

const DemoPage = ({ type }) => {
  const catalog = useCatalogConnector();
  const status = useComponentState();
  const types = type.split(",");

  const convert = (ma) => ({
    Name: "demo page",
    id: "temp-demo-page",
    demo: true,
    Animations: ma.map((m) => ({ duration: m.duration, type: m.type })),
  });

  React.useEffect(() => {
    const m = types.map((v) => catalog.getType(v));
    const ok = !!m?.length && m.every((o) => !!o);

    ok && !status.state.config && status.setState("config", convert(m));
  }, [catalog, status, types]);
  return (
    <div className="DemoPage">
      {!!status.state.config && <HoloPage config={status.state.config} />}
      {/* {type}
      <pre>{JSON.stringify(status.state.config, 0, 2)}</pre> */}
    </div>
  );
};

DemoPage.defaultProps = {};
export default DemoPage;
