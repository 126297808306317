import { Avatar, Typography } from "@mui/material";
import React from "react";
import "./Watermark.css";

const Watermark = ({ caption, icon }) => {
  const args = !!icon
    ? {}
    : { src: "/android-icon-48x48.png", alt: "watermark" };
  return (
    <div className="Watermark flex middle center">
      <Avatar style={{ margin: 2 }} {...args}>
        {icon}
      </Avatar>

      <Typography variant="caption">{caption}</Typography>
    </div>
  );
};

Watermark.defaultProps = {
  caption: "created by Hologram Generator",
};
export default Watermark;
