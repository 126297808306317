import { uuid } from "../util";

const API_ENDPOINT =
  "https://dhireyt9qg.execute-api.us-east-1.amazonaws.com/pages";

const saveHoloPage = async (page) => {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...page, uploadKey: uuid() }),
  };
  const response = await fetch(API_ENDPOINT, requestOptions);
  return await response.json();
};

const deleteHoloPage = async (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };
  const response = await fetch(`${API_ENDPOINT}/${id}`, requestOptions);
  return await response.json();
};

const getHoloPage = async (id) => {
  const response = await fetch(`${API_ENDPOINT}/${id}`);
  return await response.json();
};

const getHoloPages = async () => {
  const response = await fetch(API_ENDPOINT);
  return await response.json();
};

export { saveHoloPage, deleteHoloPage, getHoloPage, getHoloPages };
