import { Add, Close, FilterAlt, ShoppingCart } from "@mui/icons-material";
import {
  Badge,
  Box,
  Checkbox,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useCatalogConnector } from "../../Connector/CatalogApiConnector";
import { collate, css } from "../../util";
import ConfirmAlertWell, {
  useConfirmAlert,
} from "../Common/ConfirmAlertWell/ConfirmAlertWell";
import HoloPreviewCard from "../Common/HoloPreviewCard/HoloPreviewCard";
import Workspace from "../Common/Layout/Workspace/Workspace";
import PaginationBar from "../Common/PaginationBar/PaginationBar";
import "./BrowserPage.css";
import useComponentState from "../../useComponentState";
import { DegreeToggleButton } from "../Tools/VideoManager/VideoManager";
import useAuth from "../../useAuth";

const BrowserPage = ({
  title,
  primary,
  submit,
  cancel,
  param,
  degrees,
  verify,
  message,
  selectedItems,
}) => {
  const [tagFilter, setTagFilter] = React.useState([]);
  const [filter, setFilter] = React.useState(param ? [param] : []);
  const [page, setPage] = React.useState(1);
  const [cart, setCart] = React.useState([]);
  const auth = useAuth();
  const navigate = useNavigate();
  const catalog = useCatalogConnector(degrees, auth.username);
  const dialog = useConfirmAlert();
  const status = useComponentState();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const condition = auth.isAdmin()
    ? (f) => f
    : (f) => f.tags?.indexOf("admin") < 0;
  const addToCart = (addedType, addedTime, addedDegrees) => {
    const has = cart.some((f) => f.addedType === addedType);
    if (!has && verify && !verify(cart.length)) return dialog.warn(message);
    if (
      cart.length &&
      !cart.every(
        (x) =>
          x.addedDegrees === addedDegrees ||
          (!x.addedDegrees && addedDegrees === "180") ||
          (x.addedDegrees === "180" && !addedDegrees)
      )
    ) {
      return alert(
        "All holograms must be of the same type --> " + addedDegrees
      );
    }
    dialog.say(
      <>
        "{addedType}" was {!has ? "added to" : "removed from"} your cart. Click{" "}
        <b className="link" onClick={submitCart}>
          check out
        </b>{" "}
        to finish.
      </>
    );
    setCart((c) => {
      const is = c.some((f) => f.addedType === addedType);
      return is
        ? c.filter((f) => f.addedType !== addedType)
        : c.concat([
            { addedType, addedTime, addedDegrees: addedDegrees || "180" },
          ]);
    });
    status.setState("degrees", addedDegrees || "180");
  };

  const execCart = (addedType, addedTime, addedDegrees) => {
    if (!!auth.username) {
      return submit && submit(addedType, addedTime, addedDegrees || "180");
    }
    navigate(`/demo/${addedType}`);
  };

  const submitCart = () => {
    const b = ((names, object) => {
      names.map((name) => (object[name] = cart.map((c) => c[name]).join(",")));
      return object;
    })(["addedType", "addedTime", "addedDegrees"], {});
    execCart(b.addedType, b.addedTime, b.addedDegrees);
    setCart([]);
  };

  const quickCart = (addedType, addedTime, addedDegrees) => {
    execCart(addedType, addedTime, addedDegrees || "180");
    setCart([]);
  };

  const narrow = (f) => {
    setTagFilter((now) =>
      now.indexOf(f) > -1 ? now.filter((k) => k !== f) : now.concat([f])
    );
  };

  const buttons = [
    <Badge color="error" badgeContent={cart.length} onClick={submitCart}>
      <Fab color="primary">
        <ShoppingCart />
      </Fab>
    </Badge>,
  ].concat(
    !!cancel
      ? [
          <IconButton onClick={cancel}>
            <Close />
          </IconButton>,
        ]
      : []
  );
  const addPanelArgs = {
    title,
    primary,
    avatar: <Add />,
    buttons,
    src: `/apple-icon-60x60.png`,
  };

  const typeList = !tagFilter.length
    ? catalog.types.filter(condition)
    : catalog.getTags(tagFilter);

  const degreeList = !status.state.degrees
    ? typeList
    : typeList.filter(catalog.getCondition(status.state.degrees));

  const by = filter || param;
  const shown = by
    ? degreeList.filter(
        (f) =>
          f.name.toLowerCase().indexOf(by) > -1 ||
          f.video?.toLowerCase().indexOf(by) > -1 ||
          f.description?.toLowerCase().indexOf(by) > -1
      )
    : degreeList;
  const collated = collate(shown, 12, page);
  const showOptions = status.state.options || !!matches;
  return (
    <>
      <ConfirmAlertWell {...dialog.state} />
      <div className="BrowserPage">
        <Workspace {...addPanelArgs}>
          {/* {matches.toString()} */}
          <Stack
            direction={matches ? "row" : "column"}
            sx={{
              alignItems: matches ? "center" : "left",
              margin: "12px 0",
            }}
          >
            <Box
              className="flex"
              sx={{
                alignItems: "left",
                textAlign: "left",
              }}
            >
              <PaginationBar
                {...collated}
                click={(i) => {
                  setPage(page + i);
                }}
              />
              {!matches && (
                <IconButton
                  className={css({
                    auto: 1,
                    rotated: 1,
                    up: showOptions,
                    down: !showOptions,
                  })}
                  onClick={() => status.toggle("options")}
                >
                  <FilterAlt />
                </IconButton>
              )}
            </Box>
            {!!showOptions && (
              <>
                <DegreeToggleButton
                  label="Showing"
                  degree={status.state.degrees}
                  setDegree={(d) =>
                    !cart.length && status.setState("degrees", d)
                  }
                />
                <div style={matches ? { marginLeft: "auto" } : { padding: 8 }}>
                  <TextField
                    disabled={!!param}
                    label="Filter"
                    value={filter}
                    variant="outlined"
                    InputProps={{
                      endAdornment: !!filter.length ? (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setFilter([])}
                          >
                            <Close />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <i />
                      ),
                    }}
                    onChange={(e) => {
                      setFilter(e.target.value);
                      setPage(1);
                    }}
                  />
                </div>
                <MultipleSelectCheckmarks
                  tags={catalog.tagList}
                  tagFilter={tagFilter}
                  onChange={(e) => {
                    setTagFilter(e);
                    setPage(1);
                  }}
                />
              </>
            )}
          </Stack>

          <Grid container spacing={2}>
            {collated?.truncated?.map((anim, i) => {
              const existing = selectedItems?.some((c) => c.type === anim.type);
              const selected =
                cart.some((c) => c.addedType === anim.type) || existing;

              const args = {
                type: anim.type,
                duration: anim.duration,
                types: catalog.types,
                selected,
                small: !matches,
                quickUpdate: (t) => {
                  auth.isAdmin() &&
                    catalog.degreeUpdate(
                      anim.type,
                      t === "180" ? "360" : "180"
                    );
                },
                quickCart,
              };
              const xs = {
                xs: 12,
                sm: 6,
                lg: 4,
              };
              return (
                <Grid key={anim.id} item {...xs}>
                  <HoloPreviewCard
                    {...args}
                    tagFilter={tagFilter}
                    submit={addToCart}
                    onChange={narrow}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <PaginationBar
                {...collated}
                click={(i) => {
                  setPage(page + i);
                }}
              />
            </Grid>
          </Grid>
        </Workspace>
      </div>
    </>
  );
};

BrowserPage.defaultProps = {};
export default BrowserPage;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MultipleSelectCheckmarks({ tags, tagFilter, onChange }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Tags</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={tagFilter}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(",")}
          MenuProps={MenuProps}
        >
          {tags?.map((tag) => (
            <MenuItem key={tag.name} value={tag.name}>
              <Checkbox checked={tagFilter.indexOf(tag.name) > -1} />
              <ListItemText primary={`${tag.name} (${tag.count})`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
