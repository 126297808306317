import { EditSharp } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  Fab,
  Typography,
} from "@mui/material";
import React from "react";
import { useCatalogConnector } from "../../Connector/CatalogApiConnector";
import { getHoloPage } from "../../Connector/HoloApiConnector";

import useComponentState from "../../useComponentState";
import { css, mmss, sortItems } from "../../util";
import LoadingPanel from "../Common/LoadingPanel/LoadingPanel";
import SocketSender from "../Common/SocketSender/SocketSender";
import Watermark from "../Common/Watermark/Watermark";
import { Library, Media } from "../Library";
import "./HoloPage.css";

const expireTime = 15 * 60 * 1000;

const ExpiredVideo = () => (
  <Box className="flex center middle full">
    <Alert severity="warning">
      Demonstration hologram has expired. To get unlimited time please{" "}
      <Button variant="contained" href="/signin">
        Log In
      </Button>{" "}
      or <a href="/signup">Sign Up</a>.
    </Alert>
  </Box>
);

const HoloPage = (props) => {
  const catalog = useCatalogConnector(null, null, !0);
  const { setState, state } = useComponentState();
  React.useEffect(() => {
    if (props.config.demo && !state.timer) {
      setState(
        "timer",
        setTimeout(() => {
          setState("expired", true);
        }, expireTime)
      );
    }
  }, [state, setState, props]);

  if (state.expired) return <ExpiredVideo />;
  if (!catalog.types?.length)
    return (
      <LoadingPanel>The hologram library is still loading...</LoadingPanel>
    );

  const videoTypes = catalog.types.filter((f) => !!f.video);
  const baseLibrary = ((L) => {
    Object.keys(Library).map((key) => (L[key] = Library[key]));
    return L;
  })({});

  videoTypes.map(
    (t) =>
      (baseLibrary[t.type] = (props) => <Media {...props} name={t.video} />)
  );

  return <Hologram {...props} library={baseLibrary} />;
};

const Hologram = ({ config, preview, library }) => {
  const uploadKey = React.useRef(config.uploadKey);
  const [updating, setUpdating] = React.useState(null);
  let [index, setIndex] = React.useState(0);
  const [pageConfig, setPageConfig] = React.useState(config);

  const { state, setState } = useComponentState();

  const animationItems = sortItems(pageConfig.Animations);
  const setting = animationItems[index % animationItems.length];

  const style = { opacity: pageConfig.opacity };
  const debug = {
    title: config.Name,
    index,
    count: animationItems.length,
  };

  const play = React.useCallback(
    (newIndex) => {
      const nextAnim = animationItems[newIndex];

      // setReady(false);
      if (!nextAnim)
        return setState("error", "No nextAnim!!" + newIndex + "!!");
      setState("error", null);
      setIndex(newIndex);
    },
    [animationItems, setState]
  );

  const next = React.useCallback(() => {
    const newIndex = ++index % animationItems.length;
    play(newIndex);
  }, [play, animationItems, index]);

  const onMessage = (msg) => {
    // alert(JSON.stringify(msg));
    if (msg.command === "setIndex") {
      return play(msg.index);
    }
    if (msg.command === "setScene") {
      setIndex(0);
      return update(msg.id);
    }
    update(pageConfig.id);
  };

  const update = React.useCallback(async (id) => {
    setUpdating(true);
    const holo = await getHoloPage(id);
    !!holo.Item &&
      (() => {
        setPageConfig(holo.Item);
      })();
    uploadKey.current = holo.Item.uploadKey;
    setTimeout(() => {
      setUpdating(false);
    }, 1999);
  }, []);

  React.useEffect(() => {
    (() => {
      document.title = `Hologram Generator - ${config.Name}`;
    })();
  }, [config]);

  if (!setting) return <Alert>Could not find setting for {index}</Alert>;
  if (!!state.error) return <Alert>{state.error}</Alert>;
  const Component = library[setting.type];

  return (
    <div className={css({ HoloPage: 1 })} style={style}>
      {!!pageConfig.demo && <Watermark />}
      {!!preview && (
        <>
          <div className="fab left">
            {" "}
            <Fab
              color="primary"
              href={`/edit/${pageConfig.id}`}
              aria-label="add"
            >
              <EditSharp />
            </Fab>
          </div>
        </>
      )}
      {!!pageConfig.id && (
        <SocketSender
          currentIndex={index}
          onMessage={onMessage}
          keys={animationItems.map((f) => f.type)}
          type="holo"
          uploadKey={pageConfig.uploadKey}
          id={pageConfig.id}
        />
      )}

      {updating && <LoadingPanel>UPDATING...</LoadingPanel>}
      {!updating && (
        <WelcomeBox
          {...setting}
          onComplete={next}
          debug={debug}
          preview={preview}
        >
          <Component {...setting.options} {...setting} onError={next} />
        </WelcomeBox>
      )}
      {/* <HoloSwitcher {...switcher.state} /> */}
    </div>
  );
};

const StopWatch = ({ from }) => {
  const [timer, setTimer] = React.useState(0);
  const [time, setTime] = React.useState(from || 0);

  const execTimeout = React.useCallback(() => {
    !!timer && window.clearTimeout(timer);
    setTime((t) => (from ? t - 1 : t + 1));
    setTimer(setTimeout(execTimeout, 1000));
  }, [timer, from]);

  React.useEffect(() => {
    !timer && execTimeout();
  }, [execTimeout, timer]);

  return (
    <Typography variant="h2" style={{ zIndex: 50002, color: "red" }}>
      {mmss(time)}
    </Typography>
  );
};

HoloPage.defaultProps = {};
export default HoloPage;

class WelcomeBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fadeOut: false,
      timeLimit: 0,
      currentType: props.type,
      holoKey: props.id,
    };
    this.dropTimer = -1;
    this.fadeTimer = -1;
  }

  componentDidUpdate() {
    const { duration, type, id } = this.props;
    const { timeLimit, currentType, holoKey } = this.state;
    if (duration !== timeLimit || type !== currentType || id !== holoKey) {
      this.setState({ currentType: type, holoKey: id });
      this.setupExit();
    }
  }

  clearExit() {
    this.setState({ fadeOut: false });
    window.clearTimeout(this.fadeTimer);
    window.clearTimeout(this.dropTimer);
    this.fadeTimer = null;
    this.dropTimer = null;
  }

  setupExit() {
    this.clearExit();
    const { duration, onComplete } = this.props;
    const dropTime = duration - 0.25;
    const fadeTime = duration - 1.5;

    const fadeOut = () => this.setState({ fadeOut: true });
    this.setState({
      dropTime,
      fadeTime,
      timeLimit: duration,
      fadeOut: false,
    });
    this.dropTimer = setTimeout(() => {
      onComplete();
    }, dropTime * 1000);
    this.fadeTimer = setTimeout(() => {
      fadeOut();
    }, fadeTime * 1000);
  }

  componentDidMount() {
    this.setupExit();
  }

  componentWillUnmount() {
    this.clearExit();
  }
  render() {
    const { children, duration, preview, type } = this.props;
    const debug = {
      ...this.props.debug,
      ...this.state,
      duration,
    };
    return (
      <Box className={css({ HoloBox: 1, on: !this.state.fadeOut })}>
        {children}

        {!!preview && (
          <DiagnosticsCard statistics={debug} subheader={`Data for ${type}`} />
        )}
      </Box>
    );
  }
}

const DiagnosticsCard = ({ statistics, subheader }) => {
  return (
    <>
      <Card className="diagnostic">
        <CardHeader
          title={`Diagnostics`}
          subheader={subheader}
          classes={{ root: "text-left" }}
        />
        <ul>
          {Object.keys(statistics).map((key, i) => (
            <li key={i}>
              {" "}
              <label>{key}</label>
              <span>
                {typeof statistics[key] !== "object" ? (
                  statistics[key].toString()
                ) : (
                  <pre>{JSON.stringify(statistics[key])}</pre>
                )}
              </span>
            </li>
          ))}
        </ul>

        {!!statistics.timeLimit && <StopWatch from={statistics.timeLimit} />}
      </Card>
    </>
  );
};
