import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "./UserHoloList.css";
import {
  DeleteForever,
  NoteAlt,
  VideoLibrary,
  VideoSettings,
} from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useAuth from "../../../useAuth";

const ItemAvatar = ({ item, catalog }) => {
  if (!item.Animations?.length) {
    return <Avatar>?</Avatar>;
  }
  const animInfo = catalog?.filter(
    (a) => a.type === item.Animations[0].type
  )[0];

  if (!animInfo) {
    return <Avatar>!!</Avatar>;
  }
  return (
    <Avatar src={animInfo?.photo} alt={animInfo?.name}>
      {item.Animations[0].type}
    </Avatar>
  );
};

const UserHoloList = ({ items, catalog, username, onForm, onDelete }) => {
  const [on, setOn] = React.useState(false);
  const shown = items?.slice(0, 4);
  const rest = items?.slice(4);
  const auth = useAuth();
  return (
    <Stack className="UserHoloList">
      {!!auth.profile?.firstName && (
        <Box sx={{ padding: "12px 0 0 12px" }}>
          <div className="flex center">
            <Avatar style={{ marginRight: 12 }}>
              {auth.profile.firstName.substr(0, 1)}
            </Avatar>
            <strong>{`${auth.profile.firstName} ${auth.profile.lastName}`}</strong>
          </div>
          <Divider style={{ margin: "12px 0 0 0" }} />
        </Box>
      )}
      <Box sx={{ padding: "12px" }}>
        <Typography variant="h6">Holotube</Typography>
        <Typography variant="p">Pages in your collection</Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <List dense>
            {shown?.map((item, i) => (
              <UserListItem
                key={item.id}
                item={item}
                catalog={catalog}
                onDelete={onDelete}
              />
            ))}

            {!!rest?.length && (
              <ListItem onClick={() => setOn(!on)}>
                <ListItemText
                  classes={{ primary: "link" }}
                  primary={`Show ${rest.length} more pages`}
                />
              </ListItem>
            )}

            {!!on &&
              rest?.map((item, i) => (
                <UserListItem
                  key={item.id}
                  item={item}
                  catalog={catalog}
                  onDelete={onDelete}
                />
              ))}

            <ListItem onClick={() => onForm && onForm()}>
              <ListItemAvatar>
                <Avatar>
                  <NoteAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                classes={{ primary: "link" }}
                primary={
                  <Link className="link" to={`/new`}>
                    <b>Create New Page</b>
                  </Link>
                }
                secondary={`Add a new page to your collection`}
              />
            </ListItem>

            {auth.isAdmin() && (
              <>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <VideoSettings />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: "link" }}
                    primary={
                      <Link className="link" to={`/video`}>
                        Video Editor
                      </Link>
                    }
                    secondary={`Create new videos for the site`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <VideoLibrary />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: "link" }}
                    primary={
                      <Link className="link" to={`/admin`}>
                        Component Editor
                      </Link>
                    }
                    secondary={`Edit existing components`}
                  />
                </ListItem>
              </>
            )}
          </List>
        </Grid>
      </Grid>
    </Stack>
  );
};

export const UserListItem = ({ item, catalog, onDelete, onClick }) => {
  const secondaryAction = onClick ? null : (
    <IconButton
      edge="end"
      aria-label="delete"
      onClick={() => {
        onDelete && onDelete(item);
      }}
    >
      <DeleteForever />
    </IconButton>
  );
  const primary = onClick ? (
    item.Name
  ) : (
    <a className="link" href={`/edit/${item.id}/admin`}>
      {item.Name}
    </a>
  );
  return (
    <ListItem secondaryAction={secondaryAction}>
      <ListItemAvatar>
        <ItemAvatar item={item} catalog={catalog} />
      </ListItemAvatar>
      <ListItemText
        onClick={() => onClick && onClick(item)}
        classes={{ primary: "link" }}
        primary={primary}
        secondary={`${item.Animations.length} holograms`}
      />
    </ListItem>
  );
};

UserHoloList.defaultProps = {};
export default UserHoloList;
