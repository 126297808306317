import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import "./ProgressCircle.css";

const ProgressCircle = ({ progress }) => {
  return <CircularProgressWithLabel value={progress} />;
};

ProgressCircle.defaultProps = {};
export default ProgressCircle;
function CircularProgressWithLabel(props) {
  const ok = props.value > 0 && props.value < 100;
  const variant = ok ? "determinate" : "indeterminate";
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant={variant} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {ok && (
          <Typography
            variant="caption"
            component="div"
            style={{ color: "white" }}
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
