import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import useUserPageData from "../../../useUserPageData";
import useAuth from "../../../useAuth";
import usePagesMenu from "../../../usePagesMenu";
import { SettingsRemote, VideoLibrary } from "@mui/icons-material";
import { signOut } from "../../EditorPage/EditorPage";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Pages } from "../../../constants";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
export default function MainAppBar({ setDrawerOpen, setRemoteOpen }) {
  const [loaded, setLoaded] = React.useState(false);
  const [param, setParam] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileNavAnchorEl, setMobileNavAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const authenticator = useAuth();
  const navigate = useNavigate();
  const page = useUserPageData();
  const items = page.userPageData.Items;
  const pagesMenu = usePagesMenu(page);
  const pages = [
    {
      label: "browse holograms",
      go: () => navigate(Pages.BROWSER_PAGE),
      when: 1,
      // when: !!authenticator.profile?.firstName,
    },
    {
      label: "new scene",
      go: () => navigate("/new"),
      when: !!authenticator.profile?.firstName,
    },
    {
      label: "complete your profile",
      go: () => navigate("/profile"),
      when: !authenticator.profile?.firstName && authenticator.username,
    },
    {
      label: "join for free",
      go: () => navigate("/signup"),
      when: !authenticator.username,
    },
    {
      label: "edit videos",
      go: () => navigate("/video"),
      when: authenticator.isAdmin(),
    },
    {
      label: "components",
      go: () => navigate("/admin"),
      when: authenticator.isAdmin(),
    },
  ];
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isMobileNavOpen = Boolean(mobileNavAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileNavClose = () => {
    setMobileNavAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileNavOpen = (event) => {
    setMobileNavAnchorEl(event.currentTarget);
  };
  React.useEffect(() => {
    !!authenticator.username &&
      !loaded &&
      setLoaded(page.read(authenticator.username));
  }, [authenticator, loaded, page]);
  const menuId = "primary-search-account-menu";

  const renderNavMenu = (
    <Menu
      anchorEl={mobileNavAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileNavOpen}
      onClose={handleMobileNavClose}
    >
      {pages
        ?.filter((p) => p.when)
        .map((page) => (
          <MenuItem key={page.label} onClick={page.go}>
            {page.label}
          </MenuItem>
        ))}
    </Menu>
  );

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() =>
          navigate(authenticator.username ? "/profile" : "/signin")
        }
      >
        {authenticator.username ? (
          "Edit Profile"
        ) : (
          <>
            Log in or{" "}
            <Button variant="contained" style={{ marginLeft: 12 }}>
              Sign up
            </Button>
          </>
        )}
      </MenuItem>
      {authenticator.username && (
        <MenuItem onClick={() => signOut()}>Sign Out</MenuItem>
      )}
      <MenuItem
        onClick={() => {
          setRemoteOpen(true);
          handleMenuClose();
        }}
      >
        Open Remote
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" elevation={1}>
        <Toolbar>
          {!!authenticator.username && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              onClick={() => setDrawerOpen(true)}
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Avatar
            style={{ marginRight: 12 }}
            src="/apple-icon-72x72.png"
            alt="Logo"
            onClick={() => navigate(Pages.HOME_PAGE)}
          ></Avatar>
          <Typography
            onClick={() => navigate(Pages.HOME_PAGE)}
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Holotube
          </Typography>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              value={param}
              onChange={(e) => setParam(e.target.value)}
              onKeyUp={(e) => e.keyCode === 13 && navigate(`/find/${param}`)}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>

          <Box
            sx={{
              flexGrow: 1,
              display: { md: "none", xs: "none", sm: "none", lg: "flex" },
            }}
          >
            {pages
              .filter((p) => p.when)
              .map((page) => (
                <Button
                  key={page.label}
                  onClick={page.go}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page.label}
                </Button>
              ))}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              onClick={() => setRemoteOpen && setRemoteOpen(true)}
              color="inherit"
            >
              <SettingsRemote />
            </IconButton>
            {!!authenticator.username && (
              <IconButton
                size="large"
                onClick={pagesMenu.handlePageMenuOpen}
                color="inherit"
              >
                <Badge badgeContent={items?.length} color="error">
                  <VideoLibrary />
                </Badge>
              </IconButton>
            )}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            {!!authenticator.username && (
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileNavOpen}
                color="inherit"
              >
                <ExpandMore />
              </IconButton>
            )}
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {pagesMenu.renderPagesMenu}
      {renderProfileMenu}
      {renderNavMenu}
    </Box>
  );
}
