import { Grid, TextField } from "@mui/material";
import React from "react";
import "./ComponentEditForm.css";

const ComponentEditForm = ({ component }) => {
  const [object, setObject] = React.useState(component);
  const [useEditor, setUseEditor] = React.useState(false);
  const fields = [
    {
      name: "name",
    },
    {
      name: "type",
    },
    {
      name: "photo",
    },
    {
      name: "description",
      props: {
        multiline: true,
        rows: 2,
      },
    },
    {
      name: "duration",
    },
    {
      name: "editor",
      when: () => useEditor,
    },
  ];
  const args = fields.map((f) => !f.when || f.when());
  return (
    <div className="ComponentEditForm">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <img src={object.photo} alt={object.name} className="object-photo" />
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            {args.map((field, i) => (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label={field.name}
                  value={object[field.name]}
                  variant="outlined"
                  onChange={(e) => {
                    setObject((o) => ({ ...o, [field.name]: e.target.value }));
                  }}
                  {...field.props}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              check
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

ComponentEditForm.defaultProps = {};
export default ComponentEditForm;

/**    "name": "Image Carousel",
    "type": "Carousel",
    "photo": "/assets/holo/Carousel.png",
    "description": "Rotating image gallery using any 6 images",
    "duration": "infinite",
    "editor": "Carousel" */
