import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";
import "./PopoverSelect.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const PopoverSelect = ({ title, options, value, cancel, submit }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const data = typeof value === "string" ? value.split(",") : value;

    if (data.indexOf("new") > 0) {
      const name = window.prompt("Enter a name", "");
      return (
        submit && submit(data.filter((f) => !!f && f !== "new").concat(name))
      );
    }

    submit && submit(data.filter((f) => !!f));
  };

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id="demo-multiple-name-label">Tags</InputLabel>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={value}
        onBlur={() => cancel && cancel()}
        onChange={handleChange}
        input={<OutlinedInput label="Name" />}
        MenuProps={MenuProps}
      >
        <MenuItem key="new" value="new">
          add new tag...
        </MenuItem>
        {options.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

PopoverSelect.defaultProps = {};
export default PopoverSelect;
