import React from "react";
import "./SignInPage.css";
import { Auth } from "aws-amplify";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { APPLICATION_NAME, Pages } from "../../constants";
import useComponentState from "../../useComponentState";
import useCountryCodeMenu from "../../useCountryCodeMenu";

const SignInPage = ({ initialMode }) => {
  const settings = useComponentState({ mode: initialMode, isValid: !0 });
  const navigate = useNavigate();

  const setAuth = (authData) => {
    // const { username } = authData.accessToken.payload;
    // const { email, phone_number } = authData.idToken.payload;
    navigate(Pages.BROWSER_PAGE);
    settings.setState("error", null);
    settings.setState("user", { authData });
  };
  const errorMsgs = {
    UserNotFoundException: "Your username was not recognized",
    NotAuthorizedException: "The password you entered was not correct",
  };

  const headerMsgs = {
    signin: {
      title: (
        <Typography className="subheader" variant="h6">
          Please sign in
        </Typography>
      ),
      subheader: `Log in to ${APPLICATION_NAME} to get full access to our features and content.`,
    },
    signup: {
      title: (
        <Typography className="subheader" variant="h6">
          Sign up
        </Typography>
      ),
      subheader: `Sign up for a FREE account to get full access to our features and content.`,
    },
    confirm: {
      title: (
        <Typography className="subheader" variant="h6">
          Enter confirmation code
        </Typography>
      ),
      subheader: `Enter the confirmation code that was sent to ----.`,
    },
  };
  const headerMsg = headerMsgs[settings.state.mode || "signin"];

  const handleSignUp = async () => {
    const { username, password, email, phone_number } = settings.state;
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email, // optional
          phone_number, // optional - E.164 number convention
          // other custom attributes
        },
      });
      console.log(user);
      settings.setState("mode", "confirm");
    } catch (error) {
      console.log("error signing up:", error);
    }
  };
  const confirmSignUp = async () => {
    const { username, code } = settings.state;
    try {
      const answer = await Auth.confirmSignUp(username, code);
      console.log({ answer });
      handleSignIn();
    } catch (error) {
      console.log("error confirming sign up", error);
    }
  };
  const handleSignIn = async () => {
    const { username, password } = settings.state;
    try {
      const user = await Auth.signIn(username, password);
      setAuth(user);
    } catch (error) {
      settings.setState("error", { error });
    }
  };

  const signInFields = {
    values: [
      {
        name: "username",
        label: "Username",
      },
      {
        type: "password",
        name: "password",
        label: "Password",
      },
    ],
    alternate: (
      <>
        <Typography variant="body2">New to {APPLICATION_NAME}?</Typography>
        <Typography variant="caption">
          Click{" "}
          <u
            onClick={() => settings.setState("mode", "signup")}
            className="link"
          >
            here to sign up
          </u>
          !
        </Typography>
      </>
    ),
    button: "Sign In",
    action: handleSignIn,
  };
  const confirmFields = {
    values: [
      {
        name: "code",
        label: "Enter Confirmation Code",
      },
    ],
    button: "Confirm",
    action: confirmSignUp,
  };
  const signUpFields = {
    button: "Sign Up",
    values: [
      {
        name: "username",
        label: "Username",
      },
      {
        type: "password",
        name: "password",
        label: "Password",
      },
      {
        type: "email",
        name: "email",
        label: "Email Address",
      },
      {
        type: "tel",
        name: "phone_number",
        label: "Phone Number",
      },
    ],
    alternate: (
      <>
        <Typography variant="body2">Already a member?</Typography>
        <Typography variant="caption">
          Click{" "}
          <u
            onClick={() => settings.setState("mode", "signin")}
            className="link"
          >
            here to sign in
          </u>
          !
        </Typography>
      </>
    ),
    action: handleSignUp,
  };
  return (
    <div className="SignInPage flex middle center">
      <Card sx={{ maxWidth: 345 }} className="card">
        <CardHeader
          avatar={
            <Avatar src="/android-icon-36x36.png">
              <Lock />
            </Avatar>
          }
          classes={{
            title: "subheader flex left",
            subheader: "subheader flex left",
          }}
          {...headerMsg}
        />
        <Divider />
        <CardContent>
          {settings.state.error && (
            <FormHelperText error>
              {errorMsgs[settings.state.error.error.name] ||
                "Something went wrong. Please try again"}
            </FormHelperText>
          )}

          <Collapse
            in={!settings.state.mode || settings.state.mode === "signin"}
          >
            <SettingsForm settings={settings} formFields={signInFields} />
          </Collapse>
          <Collapse in={settings.state.mode === "signup"}>
            <SettingsForm settings={settings} formFields={signUpFields} />
          </Collapse>
          <Collapse in={settings.state.mode === "confirm"}>
            <SettingsForm settings={settings} formFields={confirmFields} />
          </Collapse>
        </CardContent>
        <Divider />

        <CardActions>
          <img
            src="/assets/FKA4cm2x_400x400.png"
            alt="AWS"
            className="aws-logo"
          />{" "}
          Security provided by Amazon Web Services
        </CardActions>
      </Card>{" "}
      {!!settings.state.user && (
        <pre>{JSON.stringify(settings.state.user, 0, 2)}</pre>
      )}
    </div>
  );
};

const SettingsForm = ({ formFields, settings }) => {
  return (
    <>
      <Grid container spacing={2}>
        {formFields.values.map((field) => (
          <SettingsTextField
            key={field.name}
            {...settings}
            {...field}
            action={formFields.action}
          />
        ))}

        {!!formFields.alternate && (
          <Grid item xs={12}>
            {formFields.alternate}
          </Grid>
        )}
        <Grid item xs={12} className="text-right">
          <Button variant="outlined" href="/" className="button">
            cancel
          </Button>
          <Button
            variant="contained"
            disabled={!settings.state.isValid}
            onClick={formFields.action}
          >
            {formFields.button}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
function validateEmail(email) {
  let isValid = email.search(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) !== -1;
  return isValid;
}
function validatePhoneNumber(phoneNumber) {
  let isValid =
    phoneNumber.search(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/) !==
    -1;
  return isValid;
}

const SettingsTextField = ({ state, setState, name, label, type, action }) => {
  const textState = useComponentState({
    emailIsValid: !0,
    phoneIsValid: !0,
    country: "US",
  });

  const { phoneIsValid, pswVisible, emailIsValid, country } = textState.state;
  const Icon = pswVisible ? VisibilityOff : Visibility;
  const value = state[name] || "";
  const menu = useCountryCodeMenu((u) => textState.setState("country", u.code));
  const dial_code = menu.getDialCode(country);
  function handlePhoneOnBlur(event) {
    const { value } = event.target;
    let isValid = true;
    if (type === "tel") {
      isValid = validatePhoneNumber(value);
      textState.setState("phoneIsValid", isValid || !value?.length);
    }
    if (type === "email") {
      isValid = validateEmail(value);
      textState.setState("emailIsValid", isValid || !value?.length);
    }
    setState("isValid", isValid);
  }

  const handleEnter = (e) => {
    e.keyCode === 13 && action && action();
  };

  const pswProp = {
    endAdornment:
      type === "password" ? (
        <InputAdornment position="end">
          <IconButton
            onClick={() => textState.setState("pswVisible", !pswVisible)}
          >
            {" "}
            <Icon />
          </IconButton>
        </InputAdornment>
      ) : null,
  };

  const telProp = {
    startAdornment:
      type === "tel" ? (
        <InputAdornment onClick={menu.handleNationMenuOpen} position="start">
          {dial_code}
        </InputAdornment>
      ) : null,
  };

  const inputProp = {
    tel: telProp,
    password: pswProp,
  };

  return (
    <>
      {!phoneIsValid && (
        <Grid item xs={12}>
          <Alert severity="error">Please enter a valid phone number</Alert>
        </Grid>
      )}
      {!emailIsValid && (
        <Grid item xs={12}>
          <Alert severity="error">Please enter a valid email address</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          id="outlined-basic"
          label={label}
          value={value}
          variant="outlined"
          type={pswVisible ? "text" : type}
          onBlur={handlePhoneOnBlur}
          onKeyUp={handleEnter}
          onChange={(e) => {
            setState(name, e.target.value);
          }}
          autoComplete={type === "tel" ? "tel-national" : ""}
          pattern={type === "tel" ? "[0-9]{3}-[0-9]{2}-[0-9]{3}" : ""}
          InputProps={inputProp[type]}
        />
      </Grid>
      {menu.renderNationsMenu}
    </>
  );
};

SignInPage.defaultProps = {};
export default SignInPage;
