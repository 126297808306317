import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import "./CarouselHolo.css";

const CarouselHolo = ({ images }) => {
  const [degrees, setDegrees] = React.useState(0);
  const [timer, setTimer] = React.useState(null);

  const next = React.useCallback((num) => {
    const deg = num + 420;
    setDegrees(deg);
    return setTimeout(() => next(deg), 4999);
  }, []);

  React.useEffect(() => {
    !timer && setTimer(next(degrees));
  }, [timer, next, degrees]);

  const style = { transform: `rotateY(${degrees}deg)` };
  const alphs = ["a", "b", "c", "d", "e", "f"];
  return (
    <div className="CarouselHolo">
      <div className="carousel-container">
        <div className="carousel" style={style}>
          {alphs.map((letter, i) => (
            <div key={i} className={["item", letter].join(" ")}>
              {" "}
              <img src={images[i]} alt="db" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const CarouselHoloEditor = ({ images, update }) => {
  const alphs = ["a", "b", "c", "d", "e", "f"];
  const [imageList, setImageList] = React.useState(images || alphs);
  return (
    <>
      {" "}
      <Grid container spacing={2}>
        {alphs.map((alph, k) => (
          <Grid key={k} item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label={`Image ${alph}`}
              value={imageList[k]}
              variant="outlined"
              onChange={(e) => {
                const { value } = e.target;
                const list = imageList.slice(0);
                list[k] = value;
                setImageList(list);
              }}
            />
          </Grid>
        ))}{" "}
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => update({ images: imageList })}
          >
            save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

CarouselHolo.defaultProps = {};
export default CarouselHolo;

export { CarouselHoloEditor };
