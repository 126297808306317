import VideoHolo from "../Common/VideoHolo/VideoHolo";
import CarouselHolo, { CarouselHoloEditor } from "./CarouselHolo/CarouselHolo";
import HummingbirdHolo from "./HummingbirdHolo/HummingbirdHolo";
import LoadingBarsHolo from "./LoadingBarsHolo/LoadingBarsHolo";
import PosterCircleHolo from "./PosterCircleHolo/PosterCircleHolo";
import RotatingClockHolo, {
  RotatingClockHoloEditor,
} from "./RotatingClockHolo/RotatingClockHolo";
import RubikHolo from "./RubikHolo/RubikHolo";

export const Media = ({ name, ...props }) => (
  <div>
    <VideoHolo
      {...props}
      src={`https://s3.amazonaws.com/rubiks.miltonjones.nl/assets/${name}`}
    />
  </div>
);

const EditForm = {
  Carousel: CarouselHoloEditor,
  RotatingClock: RotatingClockHoloEditor,
};

const Library = {
  Hummingbird: HummingbirdHolo,
  RubiksCube: RubikHolo,
  RotatingClock: RotatingClockHolo,
  Carousel: CarouselHolo,
  PosterCircle: PosterCircleHolo,
  LoadingBars: LoadingBarsHolo,
};

export { Library, EditForm };
