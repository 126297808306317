import { withAuthenticator } from "@aws-amplify/ui-react";
import { ChevronRight, Close, Person, ViewInAr } from "@mui/icons-material";
import { Avatar, Grid, IconButton, Stack, TextField } from "@mui/material";
import React from "react";
import {
  saveHoloType,
  useCatalogConnector,
} from "../../../Connector/CatalogApiConnector";
import { Pages } from "../../../constants";
import useAuth from "../../../useAuth";
import { collate, css } from "../../../util";
import { DegreeToggleButton } from "../../Tools/VideoManager/VideoManager";
import ComponentEditForm from "../Form/ComponentEditForm/ComponentEditForm";
import PopoverSelect from "../Form/PopoverSelect/PopoverSelect";
import HoloPreviewCard from "../HoloPreviewCard/HoloPreviewCard";
import Workspace from "../Layout/Workspace/Workspace";
import PaginationBar from "../PaginationBar/PaginationBar";
import "./HoloComponentManager.css";

const HoloComponentManager = () => {
  const authenticator = useAuth();
  const catalog = useCatalogConnector(null, authenticator.username);
  const [page, setPage] = React.useState(1);
  console.log(catalog.tags);
  if (!authenticator.profile) {
    return <em>Checking credentials...</em>;
  }

  if (!authenticator.isAdmin()) {
    return <em>You do not have access to this page!</em>;
  }

  const buttons = [
    <IconButton href={Pages.BROWSER_PAGE}>
      <Close />
    </IconButton>,
  ];

  return (
    <div className="HoloComponentManager">
      <Workspace
        avatar={<ViewInAr />}
        buttons={buttons}
        title="Manage Holo Components"
        primary="Edit or add holographic components in the library"
        secondary={`${catalog.types?.length} components in the catalog`}
      >
        <Grid container>
          <Grid item xs={12}>
            <TypeList {...catalog} page={page} setPage={setPage} />
          </Grid>
          {/* <Grid item xs={7}>
            {!!component && <ComponentEditForm component={component} />}
          </Grid> */}
        </Grid>
      </Workspace>
    </div>
  );
};

const TypeList = ({ types, update, save, tags, page, setPage }) => {
  const [filter, setFilter] = React.useState("");
  const fields = [
    {
      key: "name",
      edit: true,
    },
    {
      key: "degrees",
      toggle: true,
    },
    {
      key: "type",
    },
    {
      key: "duration",
    },
    {
      key: "tags",
      tags,
    },
    {
      key: "photo",
      edit: true,
    },
    {
      key: "description",
      edit: true,
    },
    {
      key: "editor",
      edit: true,
    },
    {
      key: "video",
      edit: true,
    },
  ];

  const query = (w) => (f) =>
    f.name.toLowerCase().indexOf(w) > -1 ||
    f.description?.toLowerCase().indexOf(w) > -1 ||
    f.video?.toLowerCase().indexOf(w) > -1;

  const shown = filter ? types.filter(query(filter)) : types;
  const collated = collate(shown, 10, page);
  return (
    <>
      <Stack direction="row" alignItems="center">
        <PaginationBar
          {...collated}
          click={(i) => {
            setPage(page + i);
          }}
        />
        <div style={{ marginLeft: "auto" }}>
          <TextField
            size="small"
            label="Filter"
            value={filter}
            variant="standard"
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      </Stack>
      <table cellSpacing="0">
        <thead>
          <th>=</th>
          <th>
            <Avatar>
              <Person />
            </Avatar>
          </th>
          {fields.map((field, i) => (
            <th key={i}>{field.key}</th>
          ))}
        </thead>
        <tbody>
          {collated?.truncated?.map((item, i) => (
            <TypeRow
              key={item.type}
              even={i % 2 === 0}
              type={item}
              types={types}
              fields={fields}
              update={update}
              save={save}
            />
          ))}
          <tr>
            <td colspan={fields.length + 2}>
              <PaginationBar
                {...collated}
                click={(i) => {
                  setPage(page + i);
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const TypeRow = ({ type, fields, update, save, even, types }) => {
  const [open, setOpen] = React.useState(false);
  const args = {
    type: type.type,
    duration: type.duration,
    types,
  };

  return (
    <>
      <tr className={css({ even })}>
        <td>
          <IconButton
            className={css({ rotated: 1, turn: open })}
            onClick={() => setOpen((d) => !d)}
          >
            <ChevronRight />
          </IconButton>
        </td>
        <td>
          <Avatar src={type.photo} alt={type.name}></Avatar>
        </td>
        {fields.map((field, i) => (
          <TypeCell
            key={type}
            type={type}
            field={field}
            update={update}
            save={save}
          />
        ))}
      </tr>
      {!!open && (
        <tr className={css({ even })}>
          <td colspan={5}>
            <HoloPreviewCard {...args} />
          </td>
          <td colspan={fields.length - 3}>?</td>
        </tr>
      )}
    </>
  );
};

const TypeCell = ({ type, field, update, save }) => {
  const [on, setOn] = React.useState(false);
  const value = type[field.key] || <em className="error">Set {field.key}</em>;
  const cancel = () => setOn(false);
  if (field.toggle && on)
    return (
      <DegreeToggleButton
        degree={type[field.key]}
        setDegree={(a) => {
          save({ ...type, degrees: a });
          cancel();
        }}
      />
    );
  if (!!field.tags && on)
    return (
      <td>
        <PopoverSelect
          cancel={cancel}
          submit={(a) => save({ ...type, tags: a.join(",") })}
          options={field.tags}
          value={(type[field.key] || "").split(",")}
        />
      </td>
    );
  if (field.edit && on)
    return (
      <EditCell
        cancel={cancel}
        type={type}
        field={field}
        submit={update}
        save={save}
      />
    );
  return (
    <td className="cell">
      <div
        onClick={() => setOn(!on)}
        className={css({ "no-wrap": 1, link: field.edit || field.tags })}
      >
        {" "}
        {value}
      </div>
    </td>
  );
};

const EditCell = ({ type, field, submit, cancel, save }) => {
  const value = type[field.key];
  const commit = async () => {
    await save(type);
    cancel();
  };
  return (
    <td style={{ minWidth: 220, maxWidth: 300 }}>
      <TextField
        fullWidth
        autoFocus
        size="small"
        id="outlined-basic"
        label={field.key}
        value={value}
        variant="standard"
        onBlur={cancel}
        onKeyUp={(e) => e.keyCode === 13 && commit()}
        onChange={(e) => submit && submit(type, field.key, e.target.value)}
      />
    </td>
  );
};

const VideoUpdater = ({ types }) => {
  console.log({ types });
  const [duration, setDuration] = React.useState(null);
  const [items, setItems] = React.useState(types);
  const [item, setItem] = React.useState(null);

  const next = React.useCallback(async () => {
    const m = items.shift();
    if (m?.video) {
      setItem(m);
    }
    setItems(items);
  }, [items]);

  React.useEffect(() => {
    next();
  }, [next]);

  const updateDuration = React.useCallback(
    async (d) => {
      const re = { ...item, duration: d };
      setItem(null);
      const res = await saveHoloType(re);
      console.log({ res });
      // alert(JSON.stringify(re, 0, 2));
      next();
      setDuration(d);
    },
    [item, next]
  );

  if (!item) return <em>wait...</em>;

  return (
    <>
      <VideoRuler src={item.video} setDuration={updateDuration} />
      <h4>{duration}</h4>
    </>
  );
};

const VideoRuler = ({ src, setDuration }) => {
  React.useEffect(() => {
    const media = document.querySelector("video");
    if (!media) return alert("No media element!");

    media.addEventListener("loadedmetadata", function (e) {
      setDuration(media.duration.toFixed(1));
    });
  }, [setDuration]);

  if (!src) return <em>No video found</em>;
  return (
    <Stack>
      [{src}]
      <video controls muted loop style={{ maxWidth: 500 }}>
        <source
          src={`https://s3.amazonaws.com/rubiks.miltonjones.nl/assets/${src}`}
          type="video/mp4"
        />
      </video>
    </Stack>
  );
};

HoloComponentManager.defaultProps = {};

export default withAuthenticator(HoloComponentManager);
