import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { getHostRoot, uuid } from "../../../util";
import { useNavigate } from "react-router-dom";
import "./CreateHoloForm.css";
import { getHoloPage, saveHoloPage } from "../../../Connector/HoloApiConnector";
import { useCatalogConnector } from "../../../Connector/CatalogApiConnector";
import HoloPreviewCard from "../HoloPreviewCard/HoloPreviewCard";
import { ChevronRight } from "@mui/icons-material";
import { DegreeToggleButton } from "../../Tools/VideoManager/VideoManager";
import useComponentState from "../../../useComponentState";
import { Pages } from "../../../constants";

const CreateHoloForm = ({
  onForm,
  submit,
  username,
  type,
  time,
  degrees = "180",
}) => {
  const navigate = useNavigate();
  const root = getHostRoot();
  const catalog = useCatalogConnector(null, username);
  const status = useComponentState();
  const typeArray = type?.split(",") || [];
  const timeArray = time?.split(",") || [];
  const degArray = degrees?.split(",") || [180];

  const [pageData, setPageData] = React.useState({
    id: "",
    name: "",
    degrees: degArray[0],
  });

  const savePage = async () => {
    const payload = {
      Animations: !!type
        ? typeArray.map((f, j) => ({
            id: uuid(),
            type: f,
            duration: timeArray[j],
          }))
        : [],
      Name: pageData.name,
      id: pageData.id,
      degrees: pageData.degrees,
      username,
    };
    const verify = await getHoloPage(payload.id);
    if (!verify.Item) {
      setPageData({ id: null, name: "" });
      await saveHoloPage(payload);
      navigate(`/edit/${payload.id}`);
      return;
    }
    status.setState(
      "error",
      `The URL "${pageData.id}" is already taken. Please choose another.`
    );
  };

  const setPageName = (e) => {
    const name = e.target.value;
    setPageData((d) => ({
      ...d,
      name,
      id: name.replace(/\s/g, "-").toLowerCase(),
    }));
  };
  const setPageId = (e) => {
    status.setState("error", null);
    setPageData((d) => ({ ...d, id: e.target.value }));
  };

  return (
    <div className="CreateHoloForm">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                autoFocus
                id="outlined-basic"
                label="Name your scene"
                value={pageData.name}
                variant="outlined"
                onChange={setPageName}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DegreeToggleButton
                setDegree={(q) => setPageData((d) => ({ ...d, degrees: q }))}
                degree={parseInt(pageData.degrees)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <div style={{ marginRight: 12, fontSize: "1.2rem" }}>
                {" "}
                {root}/view/
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                fullWidth
                id="outlined-basic"
                label="URL"
                value={pageData.id}
                variant="outlined"
                onChange={setPageId}
              />
            </Grid>
            {status.state.error}
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                variant="outlined"
                style={{ marginRight: 12 }}
                onClick={() => navigate(Pages.BROWSER_PAGE)}
              >
                Cancel
              </Button>
              <Button
                onClick={savePage}
                variant="contained"
                disabled={
                  status.state.error || !(!!pageData.id && !!pageData.name)
                }
              >
                next <ChevronRight style={{ marginLeft: 4 }} />
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              {!!typeArray?.length && (
                <Stack>
                  <Typography variant="caption">
                    {typeArray.length} hologram
                    {typeArray.length === 1 ? "" : "s"} on{" "}
                    {pageData.name || "your  newscene"}
                  </Typography>
                  <Grid container spacing={2}>
                    {typeArray.map((datum, i) => {
                      const args = {
                        type: datum,
                        duration: timeArray[i],
                        types: catalog.types,
                      };
                      return (
                        <Grid item xs={12}>
                          <HoloPreviewCard {...args} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

CreateHoloForm.defaultProps = {};
export default CreateHoloForm;
