import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import "./RotatingClockHolo.css";

const nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

function getNow() {
  const now = new Date();
  const h = now.getHours();
  const H = h > 12 ? h - 12 : h;
  const m = now.getMinutes();
  const s = now.getSeconds();
  const a = h > 11 ? "pm" : "am";
  const mm = m < 10 ? `0${m}` : m;
  const ss = s < 10 ? `0${s}` : s;
  const label = `${H === 0 ? 12 : H}:${mm}`;

  const d = m + 1;
  const v = 60 / nums.length;
  const i = Math.ceil(d / v);

  const src = `http://clock.miltonjones.nl/assets/clock-video-${i}.mp4`;

  return {
    time: label,
    seconds: ss,
    ap: a,
    src,
  };
}

const setTime = () => {
  const now = getNow();
  const time = `${now.time}
  <span>:${now.seconds}</span>
  <em>${now.ap} </em>`;

  [".cube .right", ".cube .left", ".cube .front", ".cube .back"].map((f) => {
    const divs = document.querySelectorAll(f);
    !!divs?.length &&
      Array.from(divs).map((element) => (element.innerHTML = time));
    return divs;
  });

  return setTimeout(setTime, 1000);
};

const RotatingClockHolo = ({ speed = "normal" }) => {
  const [timer, setTimer] = React.useState(null);
  React.useEffect(() => {
    !timer && setTimer(setTime());
  }, [timer]);
  return (
    <div className="RotatingClockHolo">
      <div className="RotatingClock on">
        <div className={["cube", speed].join(" ")}>
          <div className="right">00:00:00</div>
          <div className="left">00:00:00</div>
          <div className="front"> 00:00:00</div>
          <div className="back">00:00:00</div>
        </div>
      </div>
    </div>
  );
};

RotatingClockHolo.defaultProps = {};
export default RotatingClockHolo;

export const RotatingClockHoloEditor = ({ update, speed = "normal" }) => {
  const handleChange = (event, newSpeed) => {
    update({ speed: newSpeed });
  };

  return (
    <div style={{ marginBottom: 12 }}>
      Clock speed:
      <ToggleButtonGroup
        color="primary"
        value={speed}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton value="slow">slow</ToggleButton>
        <ToggleButton value="normal">normal</ToggleButton>
        <ToggleButton value="fast">fast</ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
