import React from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "./CreatePage.css";
import Workspace from "../Layout/Workspace/Workspace";
import CreateHoloForm from "../CreateHoloForm/CreateHoloForm";
import useAuth from "../../../useAuth";

const CreatePage = (props) => {
  const authenticator = useAuth();

  if (!authenticator.username) return <em>Loading...</em>;

  return (
    <div className="CreatePage">
      <Workspace
        title="Create New Holo Scene"
        primary={`You can add ${
          props.time ? "more" : ""
        } animations in the next step.`}
        padded
      >
        <>
          <CreateHoloForm username={authenticator.username} {...props} />
        </>
      </Workspace>
    </div>
  );
};

CreatePage.defaultProps = {};

export default withAuthenticator(CreatePage);
