import { Alert } from "@mui/material";
import React from "react";
import LoadingPanel from "../LoadingPanel/LoadingPanel";
import "./VideoHolo.css";

const LOADED_EVENT = "canplaythrough"; //"loadedmetadata"

const VideoHolo = ({ src, sound, onError, startTime }) => {
  const [o, setO] = React.useState(false);
  const [e, setE] = React.useState(false);
  const f = React.useRef(null);
  React.useEffect(() => {
    const vid = f.current;
    console.log({ vid, f });
    !!vid &&
      vid.addEventListener(LOADED_EVENT, () => {
        setE(null);
        setO(true);
      });
    !!vid &&
      vid.addEventListener("error", (v) => {
        // alert(`There was an error loading ${src}`);
        setE("There was an error loading this video");
        onError && onError(src);
      });
  }, [f, src, onError]);
  if (!src) {
    return <i />;
  }
  const args = {
    autoPlay: true,
    loop: true,
    muted: !sound,
    playsInline: !0,
  };

  return (
    <div className="VideoHolo">
      {!o && (
        <Alert>
          <a href={src} target="_blank" rel="noreferrer">
            Please wait while your content loads
          </a>{" "}
          [{src}]...
        </Alert>
      )}
      {!!e && (
        <LoadingPanel>
          <a href={src} target="_blank" rel="noreferrer">
            {e}
          </a>{" "}
        </LoadingPanel>
      )}
      <video ref={f} {...args} className="myVideo" id="holo-video-player">
        <source src={`${src}`} type="video/mp4" />
      </video>
    </div>
  );
};

VideoHolo.defaultProps = {};
export default VideoHolo;
