import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import "./ProfileEditForm.css";

const ProfileEditForm = ({
  firstName,
  lastName,
  phone_number,
  email,
  onForm,
  onSave,
}) => {
  const [data, setData] = React.useState({
    firstName,
    lastName,
    phone_number,
    email,
  });
  return (
    <div className="ProfileEditForm">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                autoFocus
                id="outlined-basic"
                label="First Name"
                value={data.firstName || firstName}
                variant="outlined"
                onChange={(e) =>
                  setData((d) => ({ ...d, firstName: e.target.value }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Last Name"
                value={data.lastName || lastName}
                variant="outlined"
                onChange={(e) =>
                  setData((d) => ({ ...d, lastName: e.target.value }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email Address"
                value={data.email || email}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Phone Number"
                value={data.phone_number || phone_number}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={onForm}
                style={{ marginRight: 12 }}
              >
                cancel
              </Button>
              <Button variant="contained" onClick={() => onSave(data)}>
                save
              </Button>
            </Grid>
          </Grid>
        </Grid>{" "}
      </Grid>
    </div>
  );
};

ProfileEditForm.defaultProps = {};
export default ProfileEditForm;
