import React from "react";
import VideoHolo from "../../Common/VideoHolo/VideoHolo";
import "./HummingbirdHolo.css";

const HummingbirdHolo = () => {
  return (
    <div className="HummingbirdHolo">
      <VideoHolo src="https://s3.amazonaws.com/rubiks.miltonjones.nl/assets/video-8.mp4" />
    </div>
  );
};

HummingbirdHolo.defaultProps = {};
export default HummingbirdHolo;
