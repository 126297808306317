import * as React from "react";
import { Menu, MenuItem } from "@mui/material";

export default function usePagesMenu(page, onClick) {
  const [pageAnchorEl, setPageAnchorEl] = React.useState(null);
  const items = page.userPageData.Items;

  const handlePageMenuOpen = (event) => {
    setPageAnchorEl(event.currentTarget);
  };

  const isPagesMenuOpen = Boolean(pageAnchorEl);

  const menuId = "primary-search-account-menu";

  const handlePagesMenuClose = () => {
    setPageAnchorEl(null);
  };

  const click = (x) => {
    if (onClick) {
      return onClick(x);
    }
    window.location.href = `/edit/${x.id}`;
  };

  const renderPagesMenu = (
    <Menu
      anchorEl={pageAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isPagesMenuOpen}
      onClose={handlePagesMenuClose}
    >
      {items?.map((item) => (
        <MenuItem key={item.Name} onClick={() => click(item)}>
          {item.Name}
        </MenuItem>
      ))}
    </Menu>
  );

  return {
    handlePageMenuOpen,
    isPagesMenuOpen,
    handlePagesMenuClose,
    setPageAnchorEl,
    renderPagesMenu,
  };
}
