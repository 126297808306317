import React from "react";
import "./LoadingBarsHolo.css";

const LoadingBarsHolo = () => {
  return (
    <div className="LoadingBarsHolo">
      <div id="vignette"></div>
      <div id="container">
        <div id="pos">
          <div class="cube">
            <div class="left"></div>
            <div class="center"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="shadow"></div>
          </div>
          <div class="cube">
            <div class="left"></div>
            <div class="center"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="shadow"></div>
          </div>
          <div class="cube">
            <div class="left"></div>
            <div class="center"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="shadow"></div>
          </div>
          <div class="cube">
            <div class="left"></div>
            <div class="center"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="shadow"></div>
          </div>
          <div class="cube">
            <div class="left"></div>
            <div class="center"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="shadow"></div>
          </div>
          <div class="cube">
            <div class="left"></div>
            <div class="center"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="shadow"></div>
          </div>
          <div class="cube">
            <div class="left"></div>
            <div class="center"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="shadow"></div>
          </div>
          <div class="cube">
            <div class="left"></div>
            <div class="center"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="shadow"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

LoadingBarsHolo.defaultProps = {};
export default LoadingBarsHolo;
