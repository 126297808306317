import { Lock } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Stack,
  styled,
  Typography,
  TextField,
} from "@mui/material";

import React from "react";
import { css } from "../../../../util";
import "./ListBox.css";

const Listbox = styled("ul")`
  background: #eee;
  list-style: none;
  padding: 0;
  margin: 4px 0 0 0;
  position: absolute;
  height: auto;
  transition: opacity 0.1s ease;
  z-index: 200;
  width: 95%;
  max-width: 95%;
  max-height: 240px;
  overflow-y: auto;
  box-shadow: 0 5px 13px -3px #333;
  border-radius: 0.3em;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s 0.2s ease, visibility 0.1s 0.2s step-end;
  }

  & > li {
    padding: 5px;
    cursor: pointer;
    display: flex;
    position: relative;

    &:hover {
      background: #ccc;
      color: black;
    }

    &.selected {
      border: solid 2px royalblue;
      font-weight: 600;
    }
    &.hidden {
      display: none;
    }

    &[aria-selected="true"] {
      background: #ccc;
    }

    & .filter {
      position: fixed;
      top: 0;
      height: 32px;
    }
  }
`;

const Root = styled("div")`
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  color: #000;
  width: 100%;
`;

const Toggle = styled("div")`
  min-width: 240px;
  min-width: 85%;
  width: 90%;
  overflow: hidden;
  min-height: calc(1.5em + 10px);
  padding: 5px;
  background-color: var(--color, #333);
  box-shadow: 0 5px 13px -3px var(--color, #333);
  display: inline-flex;
  align-items: center;
  justify-content: left;
  color: #fff;
  cursor: default;
  border: solid 1px #e0e0e0;
  padding: 1rem;
  border-radius: 0.4em;
  transition: background-color 0.2s linear, box-shadow 0.2s linear;

  &:hover {
    box-shadow: none;
  }

  &:active {
    outline: solid 2px royalblue;
  }

  & .placeholder {
    opacity: 0.8;
  }
`;

const CustomSelect = ({
  options,
  placeholder,
  value,
  submit,
  caption,
  image,
  progress,
  header,
  allowFilter,
}) => {
  const listboxRef = React.useRef(null);
  const [listboxVisible, setListboxVisible] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  React.useEffect(() => {
    if (listboxVisible) {
      listboxRef.current?.focus();
    }
  }, [listboxVisible]);

  const isPopulated = !!options[value];

  const placeholderText = isPopulated ? (
    <ListItem {...options[value]} />
  ) : (
    <span className="placeholder">{placeholder ?? " "}</span>
  );

  const select = (k, L) => {
    submit && submit(k, L);
    setListboxVisible(false);
  };

  const placeholderOption = {
    label: placeholderText,
    images: isPopulated || !image ? null : [image],
    caption: isPopulated ? "" : caption,
    progress,
  };

  return (
    <div className="ListBox">
      <Typography className="list-box-head">{header}</Typography>
      <Root onMouseOut={() => setListboxVisible(false)}>
        <Toggle
          onClick={() => setListboxVisible(true)}
          onFocus={() => setListboxVisible(true)}
          onBlur={() => setListboxVisible(false)}
          className={css({
            activeToggle: listboxVisible,
            selectedToggle: isPopulated && !listboxVisible,
          })}
        >
          <ListItem {...placeholderOption} />
        </Toggle>
        <Listbox
          onMouseOver={() => setListboxVisible(true)}
          className={listboxVisible ? "" : "hidden"}
        >
          {!!allowFilter && (
            <li className="filter">
              <TextField
                size="small"
                label="Filter"
                onChange={(e) => setFilterText(e.target.value)}
                value={filterText}
              />
            </li>
          )}
          {options.map((option, k) => (
            <li
              key={k}
              onClick={() => select(k, option.locked)}
              className={css({
                selected: k === value,
                hidden:
                  !!filterText &&
                  option.label.toLowerCase().indexOf(filterText.toLowerCase()) <
                    0,
              })}
            >
              <ListItem {...option} />
            </li>
          ))}
        </Listbox>
      </Root>
    </div>
  );
};

const ListItem = ({
  images,
  label,
  caption,
  locked = false,
  open = false,
  progress = <i />,
}) => {
  const avatarList =
    locked && !open ? (
      <Avatar>
        <Lock />
      </Avatar>
    ) : (
      images?.map((p, i) => <Avatar alt={p} src={p} key={i} />)
    );
  return (
    <div className="flex" style={{ width: "100%" }}>
      <div className={css({ "list-item": 1, "no-wrap": 1 })}>
        {!!images && (
          <div className="avatar">
            <AvatarGroup max={3} spacing="small">
              {avatarList}
            </AvatarGroup>
          </div>
        )}
        <div className="text">
          <Stack>
            <Typography variant="body1" className="no-wrap caps">
              {label}
            </Typography>
            <Typography variant="caption" className="no-wrap caps">
              {locked && !open ? "Private scene" : caption}
            </Typography>
          </Stack>
        </div>
      </div>
      <div className="progress auto">{progress}</div>
    </div>
  );
};

function ListBox(props) {
  return <CustomSelect {...props} />;
}
ListBox.defaultProps = {};
export default ListBox;
