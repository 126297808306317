import * as React from "react";
import { Menu, MenuItem } from "@mui/material";
import { countries } from "./countries";

export default function useCountryCodeMenu(onClick) {
  const [nationAnchorEl, setNationAnchorEl] = React.useState(null);
  const handleNationMenuOpen = (event) => {
    setNationAnchorEl(event.currentTarget);
  };

  const isNationsMenuOpen = Boolean(nationAnchorEl);

  const menuId = "primary-search-account-menu";

  const handleNationsMenuClose = () => {
    setNationAnchorEl(null);
  };

  const click = (u) => {
    onClick && onClick(u);
    handleNationsMenuClose();
  };

  const renderNationsMenu = (
    <Menu
      anchorEl={nationAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isNationsMenuOpen}
      onClose={handleNationsMenuClose}
    >
      {countries
        .filter((c) => c.name.indexOf("United") > -1)
        .map((item) => (
          <MenuItem key={item.name} onClick={() => click(item)}>
            {item.dial_code} {item.name}
          </MenuItem>
        ))}
    </Menu>
  );

  const getDialCode = (code) =>
    countries.filter((c) => c.code === code)[0].dial_code;

  return {
    handleNationMenuOpen,
    getDialCode,
    renderNationsMenu,
  };
}
