import {
  Avatar,
  Divider,
  Drawer,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useCatalogConnector } from "../../../../Connector/CatalogApiConnector";
import useAuth from "../../../../useAuth";
import useUserPageData from "../../../../useUserPageData";
import { css } from "../../../../util";
import MainAppBar from "../../MainAppBar/MainAppBar";
import RemoteControlDialog, {
  useRemoteControlDialog,
} from "../../RemoteControlDialog/RemoteControlDialog";
import SocketSender from "../../SocketSender/SocketSender";
import UserHoloList from "../../UserHoloList/UserHoloList";
import "./Workspace.css";

const Workspace = ({
  title,
  primary,
  secondary,
  buttons,
  children,
  padded,
  avatar,
  src,
  onDelete,
}) => {
  const [loaded, setLoaded] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const page = useUserPageData();
  const auth = useAuth();
  const catalog = useCatalogConnector();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const remote = useRemoteControlDialog();

  React.useEffect(() => {
    !!auth.username && !loaded && setLoaded(page.read(auth.username));
  }, [auth, loaded, page]);
  const xs = 12;
  const pageData = page.userPageData.Items;
  return (
    <>
      <MainAppBar
        items={page.userPageData.Items}
        username={auth.username}
        setRemoteOpen={() => remote.show()}
        setDrawerOpen={setDrawerOpen}
      />
      <div className={css({ Workspace: 1, mobile: !desktop })}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="header">
              {!!avatar && (
                <div className="avatar">
                  <Avatar src={src} alt={title}>
                    {avatar}
                  </Avatar>
                </div>
              )}
              <div>
                {!!secondary && (
                  <Typography variant="p">{secondary}</Typography>
                )}
                <Typography variant="h6">{title}</Typography>
                <Typography variant="p">{primary}</Typography>
              </div>
            </div>
          </Grid>
          {!!buttons && (
            <Grid item xs={12} md={6} className="flex">
              {buttons?.map((button, i) => (
                <div
                  key={i}
                  className={css({ menu: 1, auto: i === 0, first: i === 0 })}
                >
                  {button}
                </div>
              ))}
            </Grid>
          )}
        </Grid>
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(!drawerOpen)}
        >
          <UserHoloList
            onDelete={(d) => {
              setDrawerOpen(false);
              onDelete(d);
            }}
            username={auth.username}
            catalog={catalog.types}
            items={page.userPageData.Items}
          />
        </Drawer>

        <Divider style={{ marginTop: 24, marginBottom: padded ? 24 : 0 }} />

        <Grid container spacing={2}>
          <Grid item xs={xs}>
            {children}
          </Grid>
        </Grid>
      </div>{" "}
      <RemoteControlDialog {...remote.state}>
        {!!pageData && (
          <SocketSender type="client" pageData={pageData} id="main-command" />
        )}
      </RemoteControlDialog>
    </>
  );
};

Workspace.defaultProps = {};
export default Workspace;
