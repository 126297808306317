import {
  Add,
  AddAPhoto,
  CheckCircleOutline,
  Close,
  DeleteForever,
  PlayArrow,
  ShoppingCartCheckout,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { css, getWindowDimensions, mmss } from "../../../util";
import { captureVideo } from "../../Tools/VideoManager/VideoManager";
import "./HoloPreviewCard.css";

const HoloPreviewCard = ({
  types,
  type,
  submit,
  tagFilter,
  onChange,
  selected,
  quickCart,
  quickUpdate,
  small,
}) => {
  const [preview, setPreview] = React.useState(false);
  const animInfo = types?.filter((a) => a.type === type)[0];
  if (!animInfo) return <em>loading</em>;
  const Icon = !preview ? PlayArrow : Close;
  const Cart = selected ? DeleteForever : Add;
  const tags = animInfo.tags?.split(",");
  const className = css({ "no-wrap": 1, "max-160": small, "max-240": !small });
  return (
    <div className="HoloPreviewCard">
      <Card
        sx={{ display: "flex", flexDirection: "column" }}
        className={css({ selected })}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography component="div" variant="body1" className={className}>
                {animInfo.name}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                component="div"
                className={className}
              >
                duration: {mmss(animInfo.duration)}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                component="div"
                className={className}
              >
                {animInfo.description || "No description"}
              </Typography>
            </CardContent>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pl: 1,
                pb: 1,
              }}
            >
              {!!quickCart && (
                <IconButton
                  aria-label="checkout "
                  onClick={() =>
                    quickCart(type, animInfo.duration, animInfo.degrees)
                  }
                >
                  <ShoppingCartCheckout sx={{ height: 28, width: 28 }} />
                </IconButton>
              )}

              {!!animInfo.video && (
                <IconButton
                  aria-label="play/pause "
                  onClick={() => setPreview(!preview)}
                >
                  <Icon sx={{ height: 38, width: 38 }} />
                </IconButton>
              )}
              {!!submit && (
                <IconButton
                  aria-label="play/pause "
                  onClick={() =>
                    submit(type, animInfo.duration, animInfo.degrees)
                  }
                >
                  <Cart sx={{ height: 28, width: 28 }} />
                </IconButton>
              )}
            </Box>
          </Box>

          <CardMedia
            onClick={() =>
              submit && submit(type, animInfo.duration, animInfo.degrees)
            }
            component="img"
            sx={{
              width: 161,
              height: 100,
              borderRadius: "4px",
              marginLeft: "auto",
              marginTop: "2px",
              marginRight: "2px",
            }}
            image={animInfo.photo}
            alt={animInfo.name}
          />
        </Box>

        <Divider />
        <CardActions>
          {tags?.map((tag) => (
            <Chip
              style={{ margin: "0 4px" }}
              icon={
                tagFilter?.indexOf(tag) > -1 ? <CheckCircleOutline /> : null
              }
              color={tagFilter?.indexOf(tag) > -1 ? "success" : "warning"}
              label={`${tag}`}
              key={tag}
              onClick={() => onChange && onChange(tag)}
              variant="outlined"
            />
          ))}
          <Chip
            onClick={() => quickUpdate(animInfo.degrees || "180")}
            color="secondary"
            variant="outlined"
            style={{ marginLeft: "auto" }}
            label={`${animInfo.degrees || 180}`}
          ></Chip>
        </CardActions>
        <VideoCollapse open={preview} anim={animInfo} />
      </Card>
    </div>
  );
};

export const VideoCollapse = ({ open, anim }) => (
  <>
    <Collapse in={open}>
      <>
        {" "}
        <Divider />
        <CardActions>{!!open && <VideoPreview src={anim.video} />}</CardActions>
      </>
    </Collapse>
  </>
);

export const VideoPreview = ({ src, skeleton }) => {
  const player = React.useRef(null);
  const [on, setOn] = React.useState(false);
  const source = `https://s3.amazonaws.com/rubiks.miltonjones.nl/assets/${src}`;
  const dimensions = getWindowDimensions();
  const width = dimensions.width - 48;
  const height = Math.round(width * 0.66);
  const args = { width, height };
  React.useEffect(() => {
    const vid = player.current;
    !!vid &&
      vid.addEventListener("loadedmetadata", () => {
        setOn(1);
      });
  }, [player, src]);
  const display = on ? "block" : "none";
  return (
    <Stack>
      <Typography variant="button">Preview</Typography>
      {skeleton && !on && <Skeleton {...args} animation="wave" />}
      <div>
        <video
          playsInline
          autoPlay
          muted
          loop
          style={{ maxWidth: "100%", display }}
          ref={player}
        >
          <source src={source} type="video/mp4" />
        </video>
      </div>
      {/* <Typography variant="subtitle1">{src}</Typography> */}
      {/* <IconButton onClick={snapShot}>
        <AddAPhoto />
      </IconButton> */}
    </Stack>
  );
};

HoloPreviewCard.defaultProps = {};
export default HoloPreviewCard;
