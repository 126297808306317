import { ChevronRight, Launch } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useCatalogConnector } from "../../Connector/CatalogApiConnector";
import { APPLICATION_NAME, Pages } from "../../constants";
import useAuth from "../../useAuth";
import useUserPageData from "../../useUserPageData";
import { css } from "../../util";
import HoloPreviewCard from "../Common/HoloPreviewCard/HoloPreviewCard";
import MainAppBar from "../Common/MainAppBar/MainAppBar";
import "./HomePage.css";

const HomePage = () => {
  const catalog = useCatalogConnector("360");
  const [loaded, setLoaded] = React.useState(false);
  const page = useUserPageData();
  const auth = useAuth();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  React.useEffect(() => {
    !!auth.username && !loaded && setLoaded(page.read(auth.username));
  }, [auth, loaded, page]);

  if (!catalog.types.length) return "Loading...";
  const index = Math.floor(Math.random() * catalog.types.length);
  const component = catalog.types[index];

  const src = "/assets/demo-video-temp.gif";
  const dark = "/assets/holo-demo-black.gif";

  return (
    <>
      <MainAppBar items={page.userPageData.Items} username={auth.username} />
      <div className={css({ HomePage: 1, mobile: !desktop })}>
        <Grid container>
          <Grid xs={12} md={6} className="left">
            <Stack>
              <Typography variant="h2" sx={{ marginBottom: "24px" }}>
                Turn any device into a hologram.
              </Typography>
              <Typography variant="body1">
                Use <strong>{APPLICATION_NAME}</strong> to create dynamic
                holographic scenes from our library of{" "}
                <strong>hundreds of</strong> videos and Widgets.{" "}
                {APPLICATION_NAME} scenes play on any device using a simple
                holographic frame you can make at home.
              </Typography>
              <Box sx={{ margin: "24px 0" }} className="no-wrap">
                <Button variant="contained" href={Pages.BROWSER_PAGE}>
                  Get Started <ChevronRight />
                </Button>
                <Button
                  href={Pages.DEMO_PAGE}
                  target="_blank"
                  classes={{ outlined: "demo-button no-wrap" }}
                  variant="outlined"
                  color="secondary"
                >
                  {desktop ? (
                    "https://hologram-generator.com/demo"
                  ) : (
                    <>view demo</>
                  )}
                  <Launch style={{ marginLeft: 10 }} />
                </Button>
              </Box>
              <Typography variant="h4">
                Control Scenes in Scene Editor
              </Typography>
              {/* <Typography variant="body1">
                Use the <strong>{APPLICATION_NAME}</strong> Scene Editor to add
                widgets and animations to your Scenes. Registered users can
                create up to 5 hologram Scenes.
              </Typography> */}

              <SkeletonBox
                width="100%"
                className="tablet-demo"
                src="/assets/output-final.gif"
              />

              {/* <img alt="logo" /> */}
            </Stack>
          </Grid>
          <Grid xs={12} md={6} className="right">
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Stack>
                  <SkeletonBox
                    src="/assets/home-page-demo-temp.png"
                    width={412}
                    height={212}
                    className="logo"
                  />

                  {/* <img
                    className="logo"
                    src="/assets/home-page-demo-temp.png"
                    alt="logo"
                  /> */}
                  <Typography className="typo" variant="subtitle1">
                    Browse 100s of hologram files
                  </Typography>
                  <HoloPreviewCard
                    small={!desktop}
                    type={component.type}
                    types={catalog.types}
                  />
                  <Typography className="typo" variant="subtitle1">
                    Preview before saving
                  </Typography>
                  <SkeletonBox
                    src="/assets/edit-page-demo-temp.png"
                    width="100%"
                    height={212}
                    className="demo"
                  />
                  {/* <img
                    className="demo"
                    src="/assets/edit-page-demo-temp.png"
                    alt="demo"
                  /> */}
                  <Typography className="typo" variant="subtitle1">
                    Manage and control scenes in the Scene Editor
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <div>
                    <SkeletonBox
                      src={src}
                      width={240}
                      height={140}
                      className="gif-video"
                      style={{ margin: "0 0 8px 0", borderRadius: "4px" }}
                    />
                    {/* <img
                      src={`${src}`}
                      alt="video/mp4"
                      style={{
                        maxWidth: 240,
                        maxHeight: 140,
                        margin: "0 0 8px 0",
                        borderRadius: "4px",
                      }}
                    /> */}
                  </div>
                  <SkeletonBox
                    width={240}
                    height={488}
                    src="/assets/mobile-page-demo-temp.png"
                  />
                  {/* <img
                    className="demo-mobile"
                    src="/assets/mobile-page-demo-temp.png"
                    alt="mobile"
                  /> */}

                  <div>
                    <SkeletonBox
                      src={dark}
                      width={240}
                      height={140}
                      className="gif-video"
                      style={{ margin: "8px 0", borderRadius: "4px" }}
                    />
                    {/* <img
                      src={`${dark}`}
                      alt="video/mp4"
                      style={{
                        maxWidth: 240,
                        maxHeight: 140,
                        margin: "8px 0",
                        borderRadius: "4px",
                      }}
                    /> */}
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid xs={12}>other stuff</Grid> */}
        </Grid>
      </div>
    </>
  );
};

const SkeletonBox = ({ width, height, src, style, className }) => {
  const [loaded, setLoaded] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const loadImage = React.useCallback(() => {
    const im = new Image();
    im.onload = () => setReady(true);
    im.src = src;
    setLoaded(true);
  }, [setLoaded, setReady, src]);
  React.useEffect(() => {
    !loaded && loadImage();
  }, [loaded, loadImage]);
  if (!ready) {
    return (
      <Skeleton
        animation="wave"
        variant="rectangular"
        style={{ ...style }}
        width={width}
        height={height}
        className={className}
      />
    );
  }
  return <img src={src} className={className} style={style} alt="skeleton" />;
};

HomePage.defaultProps = {};
export default HomePage;
