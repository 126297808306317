import React from "react";
import { Auth } from "aws-amplify";
import { getHoloUser } from "./Connector/UserApiConnector";

export default function useAuth() {
  const [state, setState] = React.useState({});
  const [profile, setProfile] = React.useState(null);
  const isAdmin = () => state.username === "admin";
  const getUserProfile = React.useCallback(async (user) => {
    const person = await getHoloUser(user);
    setProfile(person?.Item || {});
  }, []);
  React.useEffect(() => {
    Auth.currentSession()
      .then((authData) => {
        const { username } = authData.accessToken.payload;
        const { email, phone_number } = authData.idToken.payload;
        setState((s) => ({ ...s, username, email, phone_number }));
        getUserProfile(username);
      })
      .catch((err) => console.log(err));
  }, [getUserProfile]);
  return { profile, isAdmin, ...state };
}
