const API_ENDPOINT =
  "https://c4llssupce.execute-api.us-east-1.amazonaws.com/users";

const saveHoloUser = async (user) => {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  const response = await fetch(API_ENDPOINT, requestOptions);
  return await response.json();
};

const deleteHoloUser = async (username) => {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };
  const response = await fetch(`${API_ENDPOINT}/${username}`, requestOptions);
  return await response.json();
};

const getHoloUser = async (username) => {
  const response = await fetch(`${API_ENDPOINT}/${username}`);
  return await response.json();
};

const getHoloUsers = async () => {
  const response = await fetch(API_ENDPOINT);
  return await response.json();
};

export { saveHoloUser, deleteHoloUser, getHoloUser, getHoloUsers };
