import {
  ExpandMore,
  PlayArrow,
  SignalCellular0Bar,
  SignalCellularAlt,
  SignalCellularConnectedNoInternet4Bar,
  SignalCellularNodata,
  SkipNext,
  SkipPrevious,
  Sync,
  VolumeOff,
  VolumeUp,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Grid,
  Collapse,
  Skeleton,
  Typography,
  Stack,
  Divider,
} from "@mui/material";
import React from "react";
import { useCatalogConnector } from "../../../Connector/CatalogApiConnector";
import { getHoloPage, saveHoloPage } from "../../../Connector/HoloApiConnector";
import { css, getWindowDimensions, mmss } from "../../../util";
import ConfirmAlertWell from "../ConfirmAlertWell/ConfirmAlertWell";
import Control from "../Control/Control";
import ListBox from "../Form/ListBox/ListBox";
import ProgressCircle from "../Form/ProgressCircle/ProgressCircle";
import { VideoPreview } from "../HoloPreviewCard/HoloPreviewCard";
import Watermark from "../Watermark/Watermark";
import "./SocketSender.css";

const SOCKET_URI =
  "wss://25ldvztnal.execute-api.us-east-1.amazonaws.com/production";

let client = new WebSocket(SOCKET_URI);
class SocketSender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      ws: null,
      data: null,
      mute: true,
      snackProps: { open: true },
    };
    this.openListener = this.openListener.bind(this);
    this.messageListener = this.messageListener.bind(this);
    this.closeListener = this.closeListener.bind(this);
    this.sendRefreshCommand = this.sendRefreshCommand.bind(this);
    this.setIndex = this.setIndex.bind(this);
    this.mute = this.mute.bind(this);
  }

  errorListener(error) {
    alert("There was an error in the connection. Please check the console");
    this.say(error);
  }

  openListener() {
    this.say("You are connected.");
    this.setState({ ws: client, connected: true });
    this.sendClientStatus();
  }

  curateMessage(json, indexTime) {
    let { preview } = this.state;
    const { holoItem, items, connectionId } = json;
    const my = this.getOpenSocket();
    if (json.timeOffset) {
      const key = !!json.holoItem ? json.holoItem.item.data.id : "self";
      this.setState({
        offset: {
          ...this.state.offset,
          [key]: json.timeOffset,
        },
      });
    }
    if (!!my && connectionId === my.ConnectionId) {
      !!preview && setTimeout(() => this.setState({ preview: !0 }), 999);
      preview = false;
    }
    if (!!holoItem?.item) {
      return this.updateItem(holoItem.item, preview);
    }
    !!items &&
      this.setState({
        preview,
        ...this.state,
        json: { ...json, indexTime },
      });
  }

  messageListener(message) {
    const { onMessage } = this.props;
    const { data } = message;
    const json = JSON.parse(data);
    const indexTime = new Date().getTime();
    console.log(
      "%s: Message received from '%s'.",
      indexTime,
      this.getSocketName(json.connectionId)
    );
    !!json && this.curateMessage(json, indexTime);
    !!json.command && onMessage && onMessage(json);
    !!json.currentIndex &&
      this.setState({ currentIndex: json.currentIndex, indexTime });
  }

  closeListener() {
    this.say("You are disconnected.");
    this.setState({ connected: false, ws: null });
    if (this.state.logout) {
      return this.say("Reconnection will not be attempted");
    }
    setTimeout(() => {
      this.say("Retrying connection...");
      client = new WebSocket(SOCKET_URI);
      this.mountClient();
    }, 5000);
  }

  async getSound(index) {
    const nodes = this.getCurrent();
    const id = nodes[index].data.id;
    const res = await getHoloPage(id);
    const f = res.Item;
    return f.Animations.some((e) => !!e.sound);
  }

  async setSound(index) {
    const sound = await this.getSound(index);
    this.setState({ sound });
  }

  async mute() {
    const nodes = this.getCurrent();
    const { socketIndex, sound } = this.state;
    const res = await getHoloPage(nodes[socketIndex].data.id);
    const f = res.Item;
    if (!f) return alert("NO PAGE");
    f.Animations.map((anim, i) => {
      anim.sound = !sound;
      return anim;
    });
    await saveHoloPage(f);
    this.sendRefreshCommand();
    this.setState({ sound: !sound });
  }

  sendClientException() {
    this.sendClientStatus("error", this.state);
  }

  sendClientStatus(action = "introduce", stats = {}) {
    const { type, id, currentIndex } = this.props;
    const time = new Date().getTime();
    this.sendMessage({
      action,
      data: {
        type,
        id,
        time,
        currentIndex,
        ...stats,
      },
    });
  }

  attemptAction(fn) {
    try {
      fn();
    } catch (error) {
      console.log("unable to attempt action [[%s]]", fn.toString(), { error });
    }
  }

  stateIcon() {
    const states = [
      SignalCellular0Bar,
      SignalCellularAlt,
      SignalCellularConnectedNoInternet4Bar,
      SignalCellularNodata,
    ];
    const readyState = client.readyState;
    return states[readyState] || SignalCellular0Bar;
  }

  sendMessage(json) {
    this.say("Sending message.");
    const states = ["CONNECTING", "OPEN", "CLOSING", "CLOSED"];
    const readyState = client.readyState;
    if (readyState === 1) {
      this.attemptAction(() => client.send(JSON.stringify(json)));
      return;
    }
    console.log(
      "unable to send message because the client is in %s state",
      states[readyState],
      { json }
    );
  }

  sendRefreshCommand() {
    const { socketIndex } = this.state;
    const current = this.getCurrent();
    this.sendMessage({
      action: "command",
      data: {
        ids: [current[socketIndex].ConnectionId],
        command: "Eureka!!",
      },
    });
  }

  getOpenSocket() {
    const { socketIndex } = this.state;
    const current = this.getCurrent();
    return current[socketIndex] ?? { data: {} };
  }

  getSocketName(id) {
    const current = this.getCurrent();
    console.log({ current, id });
    const socket = current.find((f) => f.ConnectionId === id);
    return socket?.data?.id || id || "[no connection id]";
  }

  filterHoloItems(items) {
    return items?.filter((f) => f.data?.type === "holo") || [];
  }

  updateItem(item, preview) {
    const name = this.getSocketName(item.ConnectionId);
    const info = this.getHoloKeyFromSocket(item);
    this.say(`Updating item '${name}': ${info}`);

    const { json } = this.state;
    const items = json.items.map((i) => {
      const updatedItem = i.ConnectionId === item.ConnectionId ? item : i;
      return updatedItem;
    });

    this.setState({ json: { items }, preview });
  }

  getCurrent() {
    return this.filterHoloItems(this.state.json?.items);
  }

  sendIndex(deviceIndex, holoIndex) {
    const current = this.getCurrent();
    const ids = current.map((f) => f.ConnectionId);
    ids[deviceIndex] !== undefined &&
      this.sendMessage({
        action: "command",
        data: {
          ids: [ids[deviceIndex]],
          command: "setIndex",
          index: holoIndex,
        },
      });
  }

  nextIndex(index) {
    const { socketIndex } = this.state;
    const current = this.getCurrent();
    const selectedIndex = current[socketIndex].data.currentIndex + index;
    this.sendIndex(socketIndex, selectedIndex);
  }

  setIndex() {
    const { socketIndex, selectedIndex } = this.state;
    this.sendIndex(socketIndex, selectedIndex);
    this.setState({ selectedIndex: undefined });
  }

  setScene(id) {
    const { socketIndex } = this.state;
    const current = this.getCurrent();
    const ids = current.map((f) => f.ConnectionId);
    ids[socketIndex] !== undefined &&
      this.sendMessage({
        action: "command",
        data: {
          ids: [ids[socketIndex]],
          command: "setScene",
          id,
        },
      });
  }

  componentDidUpdate() {
    const { currentIndex, id, uploadKey } = this.props;
    if (
      this.state.currentIndex !== currentIndex ||
      this.state.uploadKey !== uploadKey ||
      this.state.id !== id
    ) {
      this.say(">>> Updating component <<<");
      this.setState({ currentIndex, id, uploadKey });
      this.sendClientStatus();
    }
  }

  componentWillUnmount() {
    this.sendMessage({
      action: "disconnect",
    });
    this.setState({ logout: true });
  }

  mountClient() {
    client.addEventListener("open", this.openListener);
    client.addEventListener("message", this.messageListener);
    client.addEventListener("close", this.closeListener);
    client.addEventListener("error", this.errorListener);
    this.sendClientStatus();
  }

  getKeys(id) {
    const { pageData } = this.props;
    const page = pageData?.find((p) => p.id === id) ?? { Animations: [] };
    return page.Animations.map((a) => a.type);
  }

  componentDidMount() {
    this.mountClient();
  }

  getHoloKeyFromSocket(sock) {
    const { currentIndex } = sock.data;
    const openSocket = this.getOpenSocket();
    const socketKeys = this.getKeys(openSocket.data.id);
    return socketKeys[currentIndex];
  }

  getCurrentHoloKey() {
    return this.getHoloKeyFromSocket(this.getOpenSocket());
  }

  say(message, severity = "info") {
    console.log(message);
    this.setState({
      snackProps: {
        open: true,
        severity,
        message,
        onNo: () =>
          this.setState({
            snackProps: {
              open: false,
            },
          }),
      },
    });
  }

  render() {
    const { socketIndex, selectedIndex, sound, offset } = this.state;
    const { onMessage, pageData } = this.props;
    const VolumeIcon = sound ? VolumeUp : VolumeOff;

    const openSockets = this.getCurrent();
    const openSocket = this.getOpenSocket();
    const offsetSelf = offset?.self || 0;
    const currentIndex = openSocket.data.currentIndex;
    const currentTime = !!openSocket.lambdaTime
      ? openSocket.lambdaTime - offsetSelf
      : openSocket.data.time;
    // console.log({ offsetSelf });
    if (!!onMessage) {
      // onMessage means this  is being  displayed  on a
      // holo page. need better indicator here.
      const StateIcon = this.stateIcon();
      return <Watermark caption="Connected." icon={<StateIcon />} />;
    }
    return (
      <div className="SocketSender ">
        <ConfirmAlertWell snack {...this.state.snackProps} />
        <Grid container spacing={2}>
          <Grid item xs={12} className="flex center">
            {/* <div className={["indicator", connected ? "on" : "off"].join(" ")}>
              &nbsp;
            </div> */}

            {!!openSockets?.length && (
              <DeviceSelect
                pageData={pageData}
                nodes={openSockets}
                selectedValue={socketIndex}
                submit={(socketIndex) => {
                  this.setSound(socketIndex);
                  this.setState({ ...this.state, socketIndex });
                }}
              />
            )}
          </Grid>
          {socketIndex !== undefined && (
            <>
              <Grid item xs={12} className="flex center">
                {!!openSocket && (
                  <>
                    <HoloSelect
                      disabled={this.state.preview}
                      pageData={pageData}
                      id={openSocket.data.id}
                      names={this.getKeys(openSocket.data.id)}
                      submit={(selectedIndex) => {
                        this.setState({ ...this.state, selectedIndex });
                      }}
                      indexTime={this.state.json?.indexTime}
                      selectedValue={selectedIndex || currentIndex}
                      currentTime={currentTime}
                    />
                  </>
                )}
              </Grid>

              {!(selectedIndex === undefined) && (
                <Grid item xs={12}>
                  <Alert severity="info">Press play to see your changes</Alert>
                </Grid>
              )}

              <>
                <Grid
                  item
                  xs={12}
                  className={css({
                    flex: 1,
                    center: 1,
                    collapse: 1,
                    on: this.state.preview,
                  })}
                >
                  <PlayButtonPanel
                    noPlay={selectedIndex === undefined}
                    noPrev={currentIndex < 1}
                    onPrev={() => this.nextIndex(-1)}
                    onPlay={() => this.setIndex()}
                    onNext={() => this.nextIndex(1)}
                  />

                  <Control.CommonButton
                    variant="outlined"
                    className="socket-button auto sync-button"
                    onClick={this.sendRefreshCommand}
                  >
                    <Sync />
                  </Control.CommonButton>
                  <Control.CommonButton
                    variant="outlined"
                    className="socket-button sync-button"
                    onClick={this.mute}
                  >
                    <VolumeIcon />
                  </Control.CommonButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={css({
                    flex: 1,
                    center: 1,
                    collapse: 1,
                    on: this.state.preview,
                  })}
                >
                  {!!pageData?.length && (
                    <SceneSelect
                      scenes={pageData}
                      submit={(sceneIndex) => {
                        this.setScene(pageData[sceneIndex].id);
                      }}
                    />
                  )}
                </Grid>
              </>
              <Grid item xs={12} className="flex center ">
                <Control.ExpandMore
                  variant="outlined"
                  expand={this.state.preview}
                  onClick={() =>
                    this.setState({ preview: !this.state.preview })
                  }
                >
                  <ExpandMore />
                </Control.ExpandMore>
                <Divider>show preview</Divider>
              </Grid>

              <Grid item xs={12} className="flex center ">
                <Collapse
                  in={!!this.state.preview}
                  style={{ margin: 0, padding: 0 }}
                >
                  <HoloPreviewBox
                    active={!!currentTime && this.state.preview}
                    type={this.getCurrentHoloKey()}
                    start={(new Date().getTime() - currentTime) / 1000}
                  />
                </Collapse>
              </Grid>

              <Grid item xs={12} className="flex center ">
                <Button
                  fullWidth
                  variant="contained"
                  href={`/edit/${openSocket.data.id}`}
                  className="long-button"
                >
                  open in scene editor
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }
}

SocketSender.defaultProps = {};
export default SocketSender;

const HoloPreviewBox = ({ type, start, active }) => {
  const { getType } = useCatalogConnector(null, null, true);
  const anim = getType(type);
  const dimensions = getWindowDimensions();
  const width = dimensions.width - 48;
  const height = Math.round(width * 0.66);
  const skeleton = { width, height };

  if (!active) {
    return (
      <>
        <Stack>
          <Typography>Loading...</Typography>
          <Skeleton {...skeleton} animation="wave" />
        </Stack>
      </>
    );
  }
  if (!anim) {
    return <Alert severity="info">Finding {type}...</Alert>;
  }
  const { video, duration } = anim;
  const src = `${video}#t=${start},${duration}`;
  return <VideoPreview src={src} skeleton />;
};

const DeviceSelect = ({ submit, nodes, selectedValue, pageData }) => {
  const { getType } = useCatalogConnector(null, null, true);
  const [auth, setAuth] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const getPage = (n) => pageData.find((p) => p.id === n);
  const getKeys = (p) => p.Animations.map((a) => a.type);
  const options = nodes
    .filter((n) => !!n.data)
    .map((n, i) => {
      const { id, currentIndex } = n.data;
      const page = getPage(id) ?? { Name: "Unknown", Animations: [] };
      const animationKeys = getKeys(page);
      const images = animationKeys
        .map((f) => getType(f))
        .filter((f) => !!f)
        .map((f) => f.photo);
      const holo = getType(animationKeys[currentIndex]) ?? {};
      return {
        label: page.Name,
        value: i,
        images,
        caption: holo.name,
        locked: page.private,
        open,
      };
    });

  const beforeSubmit = (device, locked) => {
    if (!(locked || auth)) {
      return submit(device);
    }
    setAuth(device);
  };

  const onVerify = (user) => {
    const device = auth;
    if (!user) {
      alert("Sorry! Access denied");
      setAuth(false);
      setOpen(false);
      return;
    }
    setAuth(false);
    setOpen(true);
    submit(device);
  };

  return (
    <Stack style={{ width: "100%", margin: 0, padding: 0 }}>
      <ListBox
        header={`Remote control one of your ${options.length} devices.`}
        image="/android-icon-36x36.png"
        placeholder="select a device"
        caption={`${options.length} devices available`}
        options={options}
        value={selectedValue}
        submit={beforeSubmit}
      />
      {!!auth && (
        <Control.FeatureLogin
          setUser={onVerify}
          onCancel={() => setAuth(false)}
        >
          Please enter your password to view this scene.
        </Control.FeatureLogin>
      )}
    </Stack>
  );
};

const SceneSelect = ({ submit, scenes, selectedValue }) => {
  const { getType } = useCatalogConnector(null, null, true);
  const [auth, setAuth] = React.useState(null);

  const beforeSubmit = (device, locked) => {
    if (!(locked || auth)) {
      return submit(device);
    }
    setAuth(device);
  };

  const options = scenes.map((n) => {
    return {
      label: n.Name,
      value: n,
      locked: n.private,
      caption: ` ${n.Animations.length} Animations`,
      images: n.Animations.slice(0, 3)
        .map((f) => f.type)
        .map((t) => getType(t))
        .filter((f) => !!f)
        .map((f) => f.photo),
    };
  });

  const onVerify = (user) => {
    const device = auth;
    if (!user) {
      alert("Sorry! Access denied");
      setAuth(false);
      return;
    }
    setAuth(false);
    submit(device);
  };

  return (
    <Stack style={{ width: "100%", margin: 0, padding: 0 }}>
      <ListBox
        header={`Switch device to different scene.`}
        placeholder="Available Scenes"
        caption="select different scene to play"
        image="/android-icon-36x36.png"
        options={options}
        value={selectedValue}
        submit={beforeSubmit}
      />
      {!!auth && (
        <Control.FeatureLogin
          setUser={onVerify}
          onCancel={() => setAuth(false)}
        >
          Please enter your password to switch to this scene.
        </Control.FeatureLogin>
      )}
    </Stack>
  );
};

const HoloSelect = ({
  submit,
  names,
  selectedValue,
  pageData,
  id,
  currentTime,
  indexTime,
}) => {
  const { getType } = useCatalogConnector(null, null, true);
  const selectedPage = pageData?.find((p) => p.id === id);
  const getTime = (key) =>
    (selectedPage?.Animations?.find((f) => f.type === key) ?? { duration: 1 })
      .duration;
  const options = names.map((n) => {
    const { name, photo } = getType(n) ?? {};
    const duration = getTime(n);
    return {
      label: duration === 1 ? `[[[${n}]]]` : name,
      value: n,
      images: [photo],
      duration: duration,
      caption: `Duration: ${mmss(duration)}`,
    };
  });
  const progress = !!options[selectedValue] ? (
    <WelcomeWatch
      start={currentTime}
      duration={options[selectedValue].duration * 1000}
    />
  ) : null;
  return (
    <>
      <ListBox
        header={`Change holograms in "${selectedPage.Name}"`}
        placeholder="select a hologram"
        image="/android-icon-36x36.png"
        caption={`${names.length} holograms available`}
        options={options}
        value={selectedValue}
        submit={submit}
        progress={progress}
        allowFilter
      />
    </>
  );
};

const PlayButtonPanel = ({ onPrev, onNext, onPlay, noPlay, noPrev }) => {
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
        <Control.CommonButton
          disabled={noPrev}
          aria-label="previous"
          onClick={onPrev}
        >
          <SkipPrevious />
        </Control.CommonButton>
        <Control.CommonButton
          disabled={noPlay}
          aria-label="play/pause"
          onClick={onPlay}
        >
          <PlayArrow sx={{ height: 38, width: 38 }} />
        </Control.CommonButton>
        <Control.CommonButton aria-label="next" onClick={onNext}>
          <SkipNext />
        </Control.CommonButton>
      </Box>
    </>
  );
};

class WelcomeWatch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: 0,
      time: { start: props.start, duration: props.duration },
    };

    this.execTimeout = this.execTimeout.bind(this);
  }

  execTimeout() {
    const { start, duration } = this.state;
    const then = start + duration;
    const now = new Date().getTime();
    const until = then - now;
    const percent = 100 - Math.round((until / duration) * 100);
    this.setState({ time: { start, duration, until, percent } });
    setTimeout(this.execTimeout, 1000);
  }

  componentDidUpdate() {
    const { start, duration } = this.props;
    if (duration !== this.state.duration) {
      this.setState({ start, duration });
    }
  }

  componentDidMount() {
    this.execTimeout();
  }

  render() {
    const { time } = this.state;
    return <ProgressCircle progress={time?.percent} />;
  }
}
