import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import {
  Add,
  Close,
  Code,
  DeleteForever,
  EditSharp,
  OndemandVideo,
  PlayArrow,
  Settings,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Skeleton,
  Slider,
  Switch,
  useMediaQuery,
} from "@mui/material";
import "./HoloInfoCard.css";
import HoloOrderList from "../HoloOrderList/HoloOrderList";
import { EditForm } from "../../Library";
import { css, getWindowDimensions, mmss } from "../../../util";
import { VideoCollapse } from "../HoloPreviewCard/HoloPreviewCard";

export default function HoloInfoCard({
  // holo props

  // editing or adding
  type,
  duration,

  // for editing
  options,
  id,
  order,
  sound,
  ordinal,

  // other props
  submit,
  remove,
  length,
  // deprecated
  reorder,
  types,
  isAdmin,
  small = false,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [state, setState] = React.useState({ edit: !duration });
  const [timeValue, setTimeValue] = React.useState(duration);

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const dimensions = getWindowDimensions();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const gridItems = small ? 6 : 4;
  const cardWidth = dimensions.width / gridItems - 24;

  const PlayIcon = !state.preview ? PlayArrow : Close;
  const animInfo = types?.filter((a) => a.type === type)[0];

  if (!animInfo) {
    return (
      <>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={desktop ? cardWidth : dimensions.width - 20}
          height={small ? 265 : 375}
        />
      </>
    );
  }

  const setDurationValue = (e, value) => {
    setState((s) => ({
      ...s,
      error: value > animInfo.duration && animInfo.duration !== "infinite",
    }));
    setTimeValue(value);
  };

  const updated = (values) => ({
    type,
    duration: timeValue,
    options,
    id,
    sound,
    ...values,
  });

  const saveItem = () => {
    setState((s) => ({ ...s, edit: false }));
    !!submit && submit(id, updated());
  };

  const updateOptions = (opts) => {
    setExpanded(false);
    !!submit && submit(id, updated({ options: opts }));
  };

  const updateItemOrder = (dest, src) => {
    !!reorder && reorder(dest, src, updated());
  };

  const removeItem = () => {
    remove(id);
  };

  const typeIcon =
    animInfo.duration === "infinite" ? <Code /> : <OndemandVideo />;

  const subheader = !duration ? (
    <em>set duration</em>
  ) : (
    `Duration: ${mmss(duration)} `
  );

  const handleChange = (event) => {
    const yes = event.target.checked;
    !!submit && submit(id, updated({ sound: !yes }));
  };

  return (
    <Card className="HoloInfoCard">
      <Collapse in={state.expandCard || !small}>
        <CardHeader
          avatar={
            small ? null : (
              <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                {typeIcon}
              </Avatar>
            )
          }
          action={
            !!order ? (
              <HoloOrderList
                submit={(dest) => updateItemOrder(dest, ordinal)}
                index={order}
                length={length}
              />
            ) : (
              <IconButton onClick={() => submit && submit(type, duration)}>
                <Add />
              </IconButton>
            )
          }
          classes={{ title: css({ "no-wrap": !state.edit, short: 1 }) }}
          title={animInfo.name}
          subheader={subheader}
        />
      </Collapse>
      <CardMedia
        component="img"
        height="194"
        image={animInfo.photo}
        alt={animInfo.name}
      />
      {!!small && (
        <Box className={css({ sprocket: 1, expanded: state.expandCard })}>
          <IconButton
            onClick={() =>
              setState((s) => ({ ...s, expandCard: !s.expandCard }))
            }
          >
            <Settings />
          </IconButton>
        </Box>
      )}
      <CardContent>
        <Typography
          variant="body2"
          color="text.primary"
          className={css({ "no-wrap": !state.edit })}
        >
          {small || !state.expandCard
            ? animInfo.name
            : animInfo.description || "No description"}
        </Typography>
        {small && !state.expandCard && (
          <>
            <Typography
              variant="caption"
              color="text.secondary"
              className={css({ "no-wrap": !state.edit })}
            >
              Duration: {mmss(duration)}
            </Typography>
          </>
        )}
      </CardContent>
      <Collapse in={state.expandCard || !small}>
        {!!order && (
          <CardActions disableSpacing>
            <IconButton
              aria-label="edit"
              onClick={() => setState((s) => ({ ...s, edit: !s.edit }))}
            >
              {state.edit ? <Close /> : <EditSharp />}
            </IconButton>
            <IconButton aria-label="share" onClick={removeItem}>
              <DeleteForever />
            </IconButton>
            <IconButton
              aria-label="share"
              onClick={() => setState((s) => ({ ...s, preview: !s.preview }))}
            >
              <PlayIcon />
            </IconButton>
            {!!animInfo.editor && (
              <IconButton
                style={{ marginLeft: "auto" }}
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <Settings />
              </IconButton>
            )}
          </CardActions>
        )}
      </Collapse>

      <VideoCollapse open={state.preview} anim={animInfo} />

      <Collapse in={state.edit}>
        <div className="Box">
          <Grid container spacing={2}>
            <Grid item xs={12} className="Duration">
              <Typography>Set animation duration</Typography>
            </Grid>
            <Grid item xs={12} className="Duration">
              <Slider
                value={timeValue || 0}
                min={0}
                max={isAdmin && isAdmin() ? 600 : 60}
                onChange={setDurationValue}
              />
              <div>{mmss(timeValue)}</div>
            </Grid>

            <Grid item xs={4} className="Duration">
              <Button variant="contained" onClick={saveItem}>
                Save
              </Button>
            </Grid>
            <Grid item xs={8} className="Duration">
              <FormControlLabel
                control={
                  <Switch
                    checked={!sound}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Mute audio"
              />
            </Grid>
          </Grid>

          {!!state.error && (
            <Alert severity="warning">
              Time entered is greater than the duration of the video (
              {animInfo.duration}s)
            </Alert>
          )}
        </div>
      </Collapse>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <HoloOptionsEditor
            info={animInfo}
            options={options}
            update={updateOptions}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
}

const HoloOptionsEditor = ({ info, options, update }) => {
  if (!info?.editor) {
    return <em>No editor for this animation.</em>;
  }
  const Component = EditForm[info.editor];
  return <Component {...options} update={update} />;
};
