import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./HoloOrderList.css";
import { IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { css } from "../../../util";

const HoloOrderList = ({ submit, index, length, names }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectItem = (e) => {
    handleClose();
    submit && submit(e);
  };

  const nums = ((n) => {
    for (let i = 0; i < length; i++) {
      n.push(i + 1);
    }
    return n;
  })([]);

  return (
    <div className="HoloOrderList">
      <IconButton
        aria-label="settings"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {nums.map((num, i) => (
          <MenuItem key={i} onClick={() => selectItem(num, index)}>
            <div className={css({ bold: num === index })}>
              {" "}
              {!names ? num : names[i]}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

HoloOrderList.defaultProps = {};
export default HoloOrderList;
