import {
  CheckCircleOutline,
  Close,
  ContentCopy,
  DeleteForever,
  ExitToApp,
  ExpandMore,
  FastForward,
  FastRewind,
  MoreVert,
  Pending,
  Preview,
  Settings,
  VideoLibrary,
  VideoSettings,
} from "@mui/icons-material";
import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Slider,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useCatalogConnector } from "../../../Connector/CatalogApiConnector";
import {
  deleteBucketFile,
  getBucketFileList,
} from "../../../Connector/FileListConnector";
import { Pages } from "../../../constants";
import useAuth from "../../../useAuth";
import useComponentState from "../../../useComponentState";
import { collate, copyText, css, mmss } from "../../../util";
import Control from "../../Common/Control/Control";
import PopoverSelect from "../../Common/Form/PopoverSelect/PopoverSelect";
import Workspace from "../../Common/Layout/Workspace/Workspace";
import PaginationBar from "../../Common/PaginationBar/PaginationBar";
import VideoConfigSocket from "../../VideoConfigSocket/VideoConfigSocket";

import "./VideoManager.css";

const prefix = `https://s3.amazonaws.com/rubiks.miltonjones.nl/working`;

const VideoManager = () => {
  const inputRef = React.useRef(null);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [filePath, setFilePath] = React.useState(null);
  const [fileList, setFileList] = React.useState(null);
  const [bucketFiles, setBucketFiles] = React.useState(null);
  const [chosenFiles, setChosenFiles] = React.useState([]);
  const [lastNode, setLastNode] = React.useState([]);
  const [nodes, setNodes] = React.useState([]);
  const [showCode, setShowCode] = React.useState(false);
  const [degrees, setDegrees] = React.useState(180);
  const { state, setState } = useComponentState({ page: 1, seen: [] });
  const authenticator = useAuth();

  const catalog = useCatalogConnector(null, authenticator.username);
  const updateSelectedFile = (file) => {
    setLastNode(nodes[nodes.length - 1]);

    var fileURL = file.web || URL.createObjectURL(file);
    setSelectedFile(null);
    setTimeout(() => {
      setSelectedFile(fileURL);
      setFilePath(file.name);
    }, 299);
  };

  const getBucketFiles = async () => {
    const files = await getBucketFileList();
    console.log({ files });
    setBucketFiles(
      files?.map((f) => f.replace("working/", "")).filter((f) => !!f?.length)
    );
  };

  React.useEffect(() => {
    !bucketFiles && getBucketFiles();
  }, [bucketFiles]);

  const onFileInputChange = (e) => {
    const { files } = e.target;
    updateSelectedFile(files[0]);
    setFileList(files);
  };

  const clearNodes = async () => {
    const doomed = nodes.filter((m) => !!m.doom).map((m) => m.filePath);
    !!doomed.length && deleteFilesByKey(doomed);
    await catalog.reload();
    await getBucketFiles();
    setNodes((ns) => ns.map((n) => ({ ...n, exported: !0 })));
  };
  const activeNodes = nodes?.filter((f) => !f.exported);

  const exportButton = [
    <VideoConfigSocket nodes={activeNodes} onComplete={clearNodes} />,
  ];
  const buttons = exportButton.concat([
    <IconButton href={Pages.BROWSER_PAGE}>
      <Close />
    </IconButton>,
  ]);

  const deleteFile = async (f) => {
    if (window.confirm(`Delete ${f}? This action  CANNOT BE UNDONE!`)) {
      await deleteBucketFile(f);
      await getBucketFiles();
      setChosenFiles((cf) => cf.filter((w) => w !== f));
    }
  };

  const deleteFilesByKey = async (keys) => {
    const promises = keys.map((f) => deleteBucketFile(f));
    Promise.all(promises).then((c) => getBucketFiles());
    setChosenFiles([]);
  };

  const deleteFiles = async () => {
    if (
      window.confirm(
        `Delete ${chosenFiles.length} files? This action  CANNOT BE UNDONE!`
      )
    ) {
      return await deleteFilesByKey(chosenFiles);
    }
  };

  const openBucketFile = (f) => {
    setSelectedFile(`${prefix}/${f}`);
    setFilePath(f);
  };

  const openBucketFiles = () => {
    const files = chosenFiles.map((name) => ({
      name,
      web: `${prefix}/${name}`,
    }));
    openBucketFile(files[0].name);
    setFileList(files);
  };

  const choseFile = (file) => {
    setChosenFiles((f) =>
      f.indexOf(file) > -1 ? f.filter((i) => i !== file) : f.concat([file])
    );
  };

  if (!authenticator.isAdmin()) {
    return <em>you do not have access to this page</em>;
  }
  const collated = collate(bucketFiles, 36, state.page);

  const buttonGridRow = !chosenFiles.length ? (
    <i />
  ) : (
    <>
      {" "}
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={openBucketFiles}
          className="button"
        >
          open {chosenFiles.length} selected files
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={deleteFiles}
          className="button"
        >
          delete {chosenFiles.length} selected files
        </Button>
      </Grid>
    </>
  );

  const show = (f) =>
    setState(
      "seen",
      state.seen.indexOf(f) > -1
        ? state.seen.filter((e) => f !== e)
        : state.seen.concat([f])
    );
  return (
    <div className="VideoManager">
      <Workspace
        avatar={<VideoSettings />}
        title="Create Video Components"
        primary="Edit videos to create new components"
        buttons={buttons}
        padded
      >
        {!selectedFile && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Control.LabelBox>
                <legend>Choose a file from your computer</legend>
                <Box className="flex  center">
                  <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    onChange={onFileInputChange}
                    ref={inputRef}
                  />
                  <Button
                    variant="contained"
                    className="button"
                    onClick={() => inputRef.current.click()}
                  >
                    add files
                  </Button>
                  <DegreeToggleButton
                    label="Animation Type"
                    degree={degrees}
                    setDegree={(d) => setDegrees(d)}
                  />
                </Box>
              </Control.LabelBox>
            </Grid>

            {!!bucketFiles?.length && (
              <>
                <Grid item xs={12}>
                  <Divider>or</Divider>
                </Grid>
                {buttonGridRow}
                <Grid item xs={12}>
                  <Control.LabelBox>
                    <legend>Choose a file from S3</legend>
                    <Stack>
                      <PaginationBar
                        {...collated}
                        click={(i) => {
                          setState("page", state.page + i);
                        }}
                      />
                      <Control.EasyList>
                        {collated.truncated?.map((f, i) => {
                          const seen = state.seen.indexOf(f) > -1;
                          const gang = chosenFiles.indexOf(f) > -1;
                          const Star = gang ? Close : VideoLibrary;
                          return (
                            <>
                              <li key={i}>
                                <Box className="flex center no-wrap">
                                  <Star
                                    style={{ marginRight: 12 }}
                                    onClick={() => choseFile(f)}
                                  />
                                  <div
                                    style={{ maxWidth: "70%" }}
                                    className={css({
                                      link: 1,
                                      "no-wrap": 1,
                                      bold: chosenFiles.indexOf(f) > -1,
                                    })}
                                    onClick={() => choseFile(f)}
                                  >
                                    {f}
                                  </div>
                                  <div className="auto">
                                    {seen ? (
                                      <IconButton onClick={() => show(f)}>
                                        <Close />
                                      </IconButton>
                                    ) : (
                                      <Control.SimpleMenu
                                        options={[
                                          <>
                                            {" "}
                                            <DeleteForever /> Delete File
                                          </>,
                                          <>
                                            <Preview /> Preview
                                          </>,
                                        ]}
                                        value={1}
                                        onClick={(e) => {
                                          if (e === 0) return deleteFile(f);
                                          show(f);
                                        }}
                                        icon={<MoreVert />}
                                      />
                                    )}
                                  </div>
                                </Box>
                                <Collapse in={seen}>
                                  <VideoBox on={seen} src={`${prefix}/${f}`} />
                                </Collapse>
                              </li>
                            </>
                          );
                        })}
                      </Control.EasyList>
                    </Stack>
                  </Control.LabelBox>
                </Grid>

                {buttonGridRow}
              </>
            )}
          </Grid>
        )}
        {!!selectedFile && authenticator.username && (
          <VideoPlayer
            setFilePath={setFilePath}
            filePath={filePath}
            file={selectedFile}
            files={fileList}
            updateSelectedFile={updateSelectedFile}
            setSelectedFile={setSelectedFile}
            nodes={nodes}
            degrees={degrees}
            username={authenticator.username}
            setDegrees={setDegrees}
            lastNode={lastNode}
            catalog={catalog}
            setNodes={(n) => {
              if (n) setNodes((f) => f.concat({ ...n, filePath }));
              else setNodes([]);
            }}
          />
        )}

        <Grid container spacing={2}>
          {!!nodes &&
            Array.from(nodes).map((node, i) => (
              <Grid key={i} item xs={4}>
                {" "}
                <VideoPreview
                  name={node.name}
                  src={selectedFile}
                  img={node.preview}
                  start={node.start}
                  doom={node.doom}
                  duration={node.duration}
                  disabled={node.exported}
                  subtext={`Duration: ${node.duration.toFixed(2)}s`}
                />{" "}
              </Grid>
            ))}
        </Grid>
        {!!selectedFile && (
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <IconButton onClick={() => setShowCode(!showCode)}>
              <ExpandMore />
            </IconButton>
            show code
          </Stack>
        )}
        <Collapse in={showCode}>
          {nodes.map((node, i) => (
            <Stack key={i}>
              <pre>
                <VideoNode node={node} />
              </pre>
              <pre>
                <PhotoNode node={node} />
              </pre>
            </Stack>
          ))}
        </Collapse>
      </Workspace>
    </div>
  );
};

const baseName = (node) => {
  const name = node.name.replace(/\s/g, "-");
  const desc = node.description.replace(/\s/g, "-");
  const time = Math.round(node.duration);
  return `${name}.${desc}.${time}.${node.tags}.${node.degrees}`;
};

const VideoNode = ({ node }) => {
  const file = `${baseName(node)}.mp4`;
  return `ffmpeg -ss ${node.start} -y -i "${node.filePath}" -codec copy -t ${node.duration} "completed/${file}"`;
};

const PhotoNode = ({ node }) => {
  const file = `${baseName(node)}.jpg`;
  return `ffmpeg -ss ${node.photo} -i "${node.filePath}" -frames:v 1 -q:v 2 "images/${file}"`;
};

VideoManager.defaultProps = {};
export default VideoManager;

const DEFAULT_DATA = {
  start: 0,
  end: 0,
  duration: 0,
  photo: 0,
  name: "",
  description: "",
  file: "",
  tags: "",
  doom: true,
};

const VideoPlayer = ({
  file,
  setSelectedFile,
  updateSelectedFile,
  setNodes,
  filePath,
  files,
  nodes,
  degrees,
  setDegrees,
  username,
  catalog,
  lastNode,
}) => {
  const [progress, setProgress] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  const [player, setPlayer] = React.useState(null);
  const [controls, setControls] = React.useState(true);
  const [metaData, setMetaData] = React.useState({
    ...DEFAULT_DATA,
    description: filePath,
    ...lastNode,
    exported: !1,
  });
  const [alignment, setAlignment] = React.useState(0.25);
  const primaryPlayer = React.useRef(null);
  const save = () => {
    setNodes({ ...metaData, degrees, duration: metaData.end - metaData.start });
    setMetaData({
      ...DEFAULT_DATA,
      name: metaData.name,
      description: metaData.description,
      start: metaData.end,
      tags: metaData.tags,
      doom: metaData.doom,
    });
  };

  const handleChange = (event, newValue) => {
    !!primaryPlayer.current && (primaryPlayer.current.currentTime = newValue);
    setProgress(newValue);
  };

  const doubleChange = (event, newValue) => {
    const value = {
      start: newValue[0],
      end: newValue[1],
    };
    const currentTime = Math.max(value.start, value.end);
    handleChange(event, currentTime);
    setMetaData({
      ...metaData,
      ...value,
    });
  };

  React.useEffect(() => {
    const media = primaryPlayer.current;
    if (!media) return;
    setPlayer(media);
    media.addEventListener("loadedmetadata", function (e) {
      setDuration(media.duration);
    });

    media.addEventListener("timeupdate", function (e) {
      document.title = media.currentTime + "--" + duration;
      setProgress(media.currentTime);
    });
  }, [duration]);

  const fields = [
    {
      label: "Name",
      key: "name",
      value: metaData.name,
    },
    {
      label: "Description",
      key: "description",
      value: metaData.description,
    },
  ];

  const selectAll = () => {
    setProgress(duration);
    setMetaData((s) => ({
      ...s,
      start: 0,
      end: duration,
      photo: progress || duration * 0.33,
      preview: captureVideo(primaryPlayer.current),
    }));
  };

  const clip = metaData.end - metaData.start;

  const metaSet = (n, v) => {
    setMetaData((s) => ({ ...s, [n]: v }));
  };

  const args = { controls, playsInline: !0 };
  const isRemote = file?.indexOf(prefix) > -1;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          <IconButton
            onClick={() => {
              !!player && (player.currentTime -= alignment);
            }}
          >
            <FastRewind />
          </IconButton>
          <Slider
            min={0}
            max={duration}
            aria-label="Volume"
            value={progress}
            onChange={handleChange}
          />
          <IconButton
            onClick={() => {
              !!player && (player.currentTime += alignment);
            }}
          >
            <FastForward />
          </IconButton>
          <Control.TimeField
            id="outlined-basic"
            label="Position"
            ticks={alignment}
            time={progress}
            onChange={(e) => {
              setProgress(e);
              handleChange(null, e);
            }}
            variant="outlined"
          />
          <Collapse orientation="horizontal" in={metaData.ticks}>
            <div style={{ minWidth: 400 }}>
              <SpeedToggleButton
                alignment={alignment}
                setAlignment={setAlignment}
              />
            </div>
          </Collapse>
          <div>
            <Control.ExpandMore
              expand={metaData.ticks}
              onClick={() => {
                metaSet("ticks", !metaData.ticks);
              }}
            >
              <Settings />
            </Control.ExpandMore>
          </div>
          <Badge badgeContent={files?.length}>
            <Control.ExpandMore
              expand={metaData.show}
              onClick={() => {
                metaSet("show", !metaData.show);
              }}
            >
              <ExpandMore />
            </Control.ExpandMore>
          </Badge>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Collapse style={{ margin: 0, padding: 0 }} in={metaData.show}>
          <Grid container spacing={2}>
            {!!files &&
              Array.from(files).map((previewFile, i) => {
                const n = nodes?.filter(
                  (f) => f.filePath === previewFile.name
                )[0];
                const src = previewFile.web || URL.createObjectURL(previewFile);
                return (
                  <Grid key={i} item xs={4}>
                    {" "}
                    <VideoPreview
                      variant="caption"
                      chosen={nodes?.some(
                        (f) => f.filePath === previewFile.name
                      )}
                      img={n?.preview}
                      selected={previewFile.name === filePath}
                      subtext={n?.name}
                      choose={() => {
                        updateSelectedFile(previewFile);
                        metaSet("show", false);
                      }}
                      name={previewFile.name}
                      src={src}
                    />{" "}
                  </Grid>
                );
              })}
          </Grid>
        </Collapse>
      </Grid>

      <Grid item xs={6}>
        {/* properties form */}
        <Control.LabelBox>
          <legend>
            <ContentCopy />
            <Typography
              variant="p"
              className="no-wrap link"
              onClick={() => copyText(filePath)}
            >
              {filePath}
            </Typography>
          </legend>

          <Grid container className="form" spacing={2}>
            <Grid xs={12}>
              <Chip
                icon={metaData.start ? <CheckCircleOutline /> : <Pending />}
                className="button"
                label={`Start ${metaData.start}s`}
                variant="outlined"
                color={metaData.start ? "success" : "primary"}
                onClick={() =>
                  !!metaData.start
                    ? handleChange(null, metaData.start)
                    : metaSet("start", progress)
                }
                onDelete={() => metaSet("start", 0)}
              />
              <Chip
                icon={metaData.end ? <CheckCircleOutline /> : <Pending />}
                className="button"
                label={`End ${metaData.end}s`}
                variant="outlined"
                color={metaData.end ? "success" : "primary"}
                onClick={() =>
                  !!metaData.end
                    ? handleChange(null, metaData.end)
                    : metaSet("end", progress)
                }
                onDelete={() => metaSet("end", 0)}
              />
              <Chip
                icon={metaData.photo ? <CheckCircleOutline /> : <Pending />}
                className="button"
                label={`Photo ${metaData.photo}s`}
                variant="outlined"
                color={metaData.photo ? "success" : "primary"}
                onClick={() => {
                  metaSet("photo", progress);
                  metaSet("preview", captureVideo(primaryPlayer.current));
                }}
                onDelete={() => metaSet("photo", null)}
              />
            </Grid>
            <Grid item xs={12}>
              <Slider
                min={0}
                max={duration}
                classes={{ rail: "red" }}
                aria-label="Volume"
                onChange={doubleChange}
                color="secondary"
                value={[metaData.start, metaData.end]}
              />
            </Grid>
            <Grid xs={12}>
              <Box className="flex">
                {!!metaData.preview && !isRemote && (
                  <img
                    className="img-preview"
                    src={metaData.preview}
                    alt="preview"
                  />
                )}

                <Grid container spacing={2}>
                  {fields.map((field, i) => (
                    <Grid item xs={12} key={i}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label={field.label}
                        value={metaData[field.key]}
                        onChange={(e) => metaSet(field.key, e.target.value)}
                        variant="outlined"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <PopoverSelect
                submit={(a) => metaSet("tags", a.join(","))}
                options={catalog.tags}
                value={(metaData.tags || "").split(",")}
              />
            </Grid>
            <Grid item xs={5} className="flex middle">
              <Stack className="flex middle">
                {clip > 0 && <Typography>Duration: {mmss(clip)}</Typography>}
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="outlined"
                className="button"
                onClick={() => {
                  setSelectedFile(null);
                }}
              >
                cancel
              </Button>
              <Button
                disabled={
                  clip < 1 ||
                  !filePath ||
                  !metaData.name ||
                  !metaData.description ||
                  !metaData.tags ||
                  !metaData.photo
                }
                variant="contained"
                onClick={save}
                className="button"
              >
                save
              </Button>
              <Button
                disabled={!metaData.name}
                variant="contained"
                onClick={selectAll}
                className="button"
              >
                select all
              </Button>

              {isRemote && (
                <Control.BasicSwitch
                  checked={!!metaData.doom}
                  label="Delete when done"
                  onChange={(s) => {
                    metaSet("doom", s);
                  }}
                />
              )}
            </Grid>
            {isRemote && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  Using remote files. Preview is disabled
                </Alert>
              </Grid>
            )}
          </Grid>
        </Control.LabelBox>
      </Grid>
      <Grid item xs={6}>
        <Control.LabelBox>
          <legend>
            {" "}
            <VideoSettings />
            <Typography variant="p" className="no-wrap">
              Preview
            </Typography>
          </legend>
          <Stack direction="row">
            <DegreeToggleButton
              degree={degrees}
              setDegree={(d) => setDegrees(d)}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={controls}
                  onChange={(e) => setControls(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={`Controls`}
            />
          </Stack>
          <video
            {...args}
            muted
            loop
            style={{ maxWidth: 500 }}
            ref={primaryPlayer}
          >
            <source src={file} type="video/mp4" />
          </video>
        </Control.LabelBox>
      </Grid>
    </Grid>
  );
};

function SpeedToggleButton({ alignment, setAlignment }) {
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <div style={{ marginBottom: 12 }}>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton value={0.1}>0.1s</ToggleButton>
        <ToggleButton value={0.25}>0.25s</ToggleButton>
        <ToggleButton value={1}>1s</ToggleButton>
        <ToggleButton value={5}>5s</ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

export function DegreeToggleButton({
  degree = "",
  label = "Type:",
  setDegree,
}) {
  const handleChange = (event, newAlignment) => {
    setDegree(newAlignment);
  };

  return (
    <div style={{ margin: "0 12px" }}>
      {label}
      <ToggleButtonGroup
        color="primary"
        value={degree}
        exclusive
        onChange={handleChange}
      >
        {!!label && <ToggleButton value="">all</ToggleButton>}
        <ToggleButton value="180">180</ToggleButton>
        <ToggleButton value="360">360</ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

export const captureVideo = (media, width = 640, height = 480) => {
  const video = media || document.querySelector("video");
  var canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  try {
    //convert to desired file format
    var dataURI = canvas.toDataURL("image/jpeg");
    return dataURI;
  } catch (e) {
    return e;
  }
};

const VideoPreview = ({
  src,
  name,
  choose,
  chosen,
  img,
  doom,
  subtext,
  disabled,
  start,
  duration,
  variant = "h6",
  selected,
}) => {
  const pp = React.useRef(null);
  const [on, setOn] = React.useState(false);
  const t = !duration ? "" : `#t=${start},${start + duration}`;
  const args = {
    controls: true,
    playsInline: !0,
    autoPlay: true,
  };
  const remote = src?.indexOf(prefix) > -1;
  if (name)
    return (
      <Card onClick={choose} className={css({ disabled })}>
        <Box
          sx={{
            display: "flex",
            backgroundColor: selected ? "aliceblue" : "white",
          }}
        >
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              component="div"
              variant={variant}
              className={css({ preview: 1, chosen, disabled })}
            >
              {name}
            </Typography>
            {!!subtext && (
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                [{doom?.toString()}] {subtext}
              </Typography>
            )}
          </CardContent>
          {!!img && !remote && (
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={img}
              alt={name}
            />
          )}
        </Box>
        {!!duration && (
          <>
            {" "}
            <Divider />
            <CardActions>
              <IconButton onClick={() => setOn(!on)}>
                <ExpandMore />
              </IconButton>
            </CardActions>
            {on && (
              <CardActions>
                <video
                  muted
                  loop
                  style={{ maxWidth: "100%" }}
                  {...args}
                  ref={pp}
                >
                  <source src={`${src}${t}`} type="video/mp4" />
                </video>
              </CardActions>
            )}
          </>
        )}
      </Card>
    );

  if (!src) return <em>No video found</em>;
  return <Stack>[{name}]</Stack>;
};

const VideoBox = ({ src, on }) => {
  const pp = React.useRef(null);
  const args = {
    playsInline: !0,
    autoPlay: true,
  };
  if (!on) return <i />;
  return (
    <>
      <video muted loop style={{ maxWidth: "100%" }} {...args} ref={pp}>
        <source src={src} type="video/mp4" />
      </video>
    </>
  );
};
