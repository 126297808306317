import React from "react";
import {
  getHoloPages,
  saveHoloPage,
  getHoloPage,
  deleteHoloPage,
} from "../../Connector/HoloApiConnector";
import "./EditorPage.css";
import {
  Button,
  Collapse,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Slider,
  Switch,
  TextField,
} from "@mui/material";
import HoloInfoCard from "../Common/HoloInfoCard/HoloInfoCard";
import {
  Add,
  Close,
  EditSharp,
  ExitToApp,
  GridView,
  Launch,
  SettingsSharp,
  ViewComfy,
} from "@mui/icons-material";
import { collate, sortItems, uuid } from "../../util";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { useCatalogConnector } from "../../Connector/CatalogApiConnector";
import ConfirmAlertWell, {
  useConfirmAlert,
} from "../Common/ConfirmAlertWell/ConfirmAlertWell";
import Workspace from "../Common/Layout/Workspace/Workspace";
import BrowserPage from "../BrowserPage/BrowserPage";
import { Pages } from "../../constants";
import usePagesMenu from "../../usePagesMenu";
import { SocketConnector } from "../../useSocket";
import PaginationBar from "../Common/PaginationBar/PaginationBar";
import SocketSender from "../Common/SocketSender/SocketSender";
import LoadingPanel from "../Common/LoadingPanel/LoadingPanel";

export async function signOut() {
  try {
    await Auth.signOut();
    window.location.reload();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

const move = (array, from, to) => {
  array.splice(to, 0, array.splice(from, 1)[0]);
  return array;
};

const EditorPage = ({ id }) => {
  const [loaded, setLoaded] = React.useState(false);
  const [state, setState] = React.useState({ smallForm: true });
  const [pageData, setData] = React.useState([]);

  const [selectedPage, setSelectedPage] = React.useState(null);

  const isAdmin = () => state.username === "admin";
  const pagesMenu = usePagesMenu(
    {
      userPageData: {
        Items: pageData,
      },
    },
    (o) => {
      persist({ ...selectedPage, command: { key: "go", url: o.id } });
      setTimeout(() => {
        window.location.href = `/edit/${o.id}`;
      }, 3999);
    }
  );
  const catalog = useCatalogConnector(null, state.username);
  const dialog = useConfirmAlert();
  // const web = useSocket(
  //   () => console.log("CONNECTION OPEN"),
  //   (e) => console.log("MESSAGE RECEIVED " + e),
  //   () => console.log("CONNECTION CLOSED")
  // );

  // const web = SocketConnector();

  const read = React.useCallback(async (name) => {
    const res = await getHoloPages();
    res.Items = res.Items.filter(
      (item) => item.username === name || (!item.username && name === "admin")
    );
    setData(res.Items);
    return true;
  }, []);

  const bringItem = React.useCallback(
    async (key) => {
      const upd = await getHoloPage(key);
      const name = upd?.Item?.Name;
      document.title = `Scene Editor - ${name}`;
      !!upd?.Item && setSelectedPage(upd.Item);
    },
    [setSelectedPage]
  );

  const persist = async (f) => {
    await saveHoloPage(f);
    bringItem(f.id);
    dialog.say(
      `${f.Name} was saved. Please wait a few seconds to see your changes`
    );
  };
  const onDelete = async (item) => {
    dialog.ask(`Delete page "${item.Name}"?`, async () => {
      const res = await deleteHoloPage(item.id);
      alert(res);
      setLoaded(read(state.username));
    });
  };

  const reorder = async (dest, src, object) => {
    const f = selectedPage;

    f.Animations = move(f.Animations, src, dest - 1);
    f.Animations.map((anim, i) => {
      anim.order = i + 1;
      return anim;
    });

    await persist(f);
  };
  const muteAll = async (sound) => {
    const f = selectedPage;

    f.Animations.map((anim, i) => {
      anim.sound = sound;
      return anim;
    });

    await persist(f);
  };

  const update = async (f) => {
    await persist(f);
  };

  const saveItem = async (animId, object) => {
    const f = selectedPage;

    f.Animations = ((rack) => {
      f.Animations.map((anim) => rack.push(anim.id === animId ? object : anim));
      return rack;
    })([]);

    await persist(f);
  };

  const addItem = async (type, duration, degrees) => {
    const f = selectedPage;
    // alert([type, duration, degrees].join("--"));
    const grid = {
      type: type?.split(",") || [],
      duration: duration?.toString().split(",") || [],
      degrees: (degrees || "180").split(",") || [],
    };
    grid.type.map((q, i) => {
      const key = f.Animations.length + i + 1;
      return f.Animations.push({
        type: q,
        duration: grid.duration[i],
        degrees: grid.degrees[i] || "180",
        order: key,
        id: uuid(),
      });
    });

    setState((s) => ({ ...s, adding: false }));
    await persist(f);
  };

  const deleteItem = (id) => {
    dialog.ask(`Delete animation "${id}"?`, async () => {
      const f = selectedPage;
      f.Animations = ((rack) => {
        f.Animations.map((anim, i) => anim.id !== id && rack.push(anim));
        return rack;
      })([]);
      await persist(f);
    });
  };

  // const getUserProfile = React.useCallback(async (user) => {
  //   const person = await getHoloUser(user);
  //   setProfile(person?.Item || {});
  // }, []);

  React.useEffect(() => {
    !!id && !selectedPage && bringItem(id);
    // web.init((msg) => {
    //   console.log({ msg });
    // });

    Auth.currentSession()
      .then((authData) => {
        const { username } = authData.accessToken.payload;
        const { email, phone_number } = authData.idToken.payload;
        setState((s) => ({ ...s, username, email, phone_number }));
        // getUserProfile(username);
        !loaded && setLoaded(read(username));
        // page.read(username);
      })
      .catch((err) => console.log(err));
  }, [read, loaded, id, selectedPage, bringItem]);

  const domain = window.location.hostname;
  const port = domain === "localhost" ? ":3000" : "";
  const root = `http://${domain + port}`;

  if (!state.username) {
    return <em>who are you??!</em>;
  }

  if (!selectedPage) {
    return <LoadingPanel>loading...</LoadingPanel>;
  }

  const canFab = isAdmin() || selectedPage.Animations?.length < 3;
  const previewURL = `${root}/preview/${selectedPage.id}`;
  const renderURL = `${root}/view/${selectedPage.id}`;

  const editPanelArgs = {
    onDelete,
    secondary: (
      <>
        View your page at <a href={renderURL}>/view/{selectedPage.id}</a>
      </>
    ),
    title: <>"{selectedPage.Name}"</>,
    avatar: <EditSharp />,
    primary: "Select an animations to edit it.",
    buttons: [
      <Button color="primary" href={previewURL} variant="contained">
        preview <Launch style={{ marginLeft: 8 }} />
      </Button>,
      <IconButton
        onClick={() => setState((s) => ({ ...s, nameForm: !s.nameForm }))}
      >
        <SettingsSharp />
      </IconButton>,
      <IconButton color="secondary" href={Pages.BROWSER_PAGE}>
        <Close />
      </IconButton>,
    ],
  };

  const message =
    "You can only add 3 holograms to a page. Remove some items and try again.";

  const anySound = selectedPage.Animations.some((f) => !!f.sound);
  const handleChange = (event) => {
    muteAll(!anySound);
  };
  const shownItems = sortItems(selectedPage.Animations);
  const collated = collate(
    shownItems,
    state.smallForm ? 24 : 12,
    state.page || 1
  );
  // const keys = selectedPage.Animations.map(
  //   (a) => catalog.getType(a.type).photo
  // );
  return (
    <>
      <ConfirmAlertWell {...dialog.state} dialog />
      <div className="EditorPage">
        <Collapse in={state.adding}>
          <BrowserPage
            verify={(c) => selectedPage.Animations.length + c < 3 || isAdmin()}
            selectedItems={selectedPage.Animations}
            message={message}
            title={`Add an animation to "${selectedPage.Name}"`}
            primary="Select an item to add it to your page."
            degrees={selectedPage.degrees || "180"}
            cancel={() => setState((s) => ({ ...s, adding: !s.adding }))}
            submit={addItem}
            username={state.username}
          />
        </Collapse>
        <Collapse in={!state.adding}>
          <Workspace {...editPanelArgs} padded>
            {!!canFab && (
              <div className="fab right">
                <Fab
                  color="primary"
                  onClick={() => setState((s) => ({ ...s, adding: !s.adding }))}
                >
                  <Add />
                </Fab>
              </div>
            )}
            <Collapse in={state.nameForm}>
              <Grid container spacing={2} style={{ marginBottom: 12 }}>
                <Grid item xs={6} md={2}>
                  <TextField
                    fullWidth
                    autoFocus
                    id="outlined-basic"
                    label="Page name"
                    value={selectedPage.Name}
                    variant="outlined"
                    onChange={(e) => {
                      setSelectedPage((s) => ({ ...s, Name: e.target.value }));
                    }}
                  />
                </Grid>

                <Grid item xs={3} md={1} className="flex center">
                  <Slider
                    value={selectedPage.opacity || 1}
                    min={0.1}
                    max={1}
                    step={0.1}
                    marks
                    onChange={(e, n) => {
                      setSelectedPage((s) => ({
                        ...s,
                        opacity: e.target.value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={3} md={1} className="flex center">
                  <div>Dimmer: {selectedPage.opacity || 1}</div>
                </Grid>

                <Grid item xs={6} md={1} className="flex center">
                  <Button
                    variant="contained"
                    onClick={() => update(selectedPage)}
                  >
                    save
                  </Button>{" "}
                </Grid>

                <Grid item xs={6} md={2} className="flex center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!anySound}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={`${!anySound ? "unmute" : "mute"} all audio`}
                  />
                </Grid>

                <Grid item xs={6} md={2} className="flex center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!selectedPage.private}
                        onChange={() => {
                          persist({
                            ...selectedPage,
                            private: !selectedPage.private,
                          });
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Scene is private"
                  />
                </Grid>
                {/* {isAdmin() && (
                  <Grid item xs={12} md={12} className="flex center">
                    {!!selectedPage.id && (
                      <SocketSender
                        type="client"
                        pageData={pageData}
                        id={selectedPage.id}
                      />
                    )}
                  </Grid>
                )} */}
              </Grid>
            </Collapse>

            {!!state.username && (
              <Grid container spacing={4}>
                <Grid item xs={11}>
                  <PaginationBar
                    {...collated}
                    click={(i) => {
                      setState((s) => ({ ...s, page: (s.page || 1) + i }));
                    }}
                  />
                  {/* {JSON.stringify(keys)} */}
                </Grid>
                <Grid item xs={1} className="text-right">
                  <IconButton
                    onClick={() =>
                      setState((s) => ({
                        ...s,
                        smallForm: !s.smallForm,
                        page: 1,
                      }))
                    }
                  >
                    {state.smallForm ? <ViewComfy /> : <GridView />}
                  </IconButton>
                </Grid>
                {collated?.truncated?.map((anim, i) => {
                  const args = {
                    ...anim,
                    length: selectedPage.Animations.length,
                    types: catalog.types,
                    isAdmin,
                  };
                  return (
                    <Grid
                      key={i}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={state.smallForm ? 2 : 3}
                    >
                      <HoloInfoCard
                        {...args}
                        small={state.smallForm}
                        ordinal={collated.startPage + i}
                        reorder={reorder}
                        submit={saveItem}
                        remove={deleteItem}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Workspace>
        </Collapse>
      </div>
      {pagesMenu.renderPagesMenu}
    </>
  );
};

EditorPage.defaultProps = {};

export default withAuthenticator(EditorPage);
