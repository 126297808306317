import React from "react";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import HoloPage from "./components/HoloPage/HoloPage";
import HoloConfig from "./config/basic.json";
import EditorPage from "./components/EditorPage/EditorPage";
import { getHoloPage } from "./Connector/HoloApiConnector";
import HoloComponentManager from "./components/Common/HoloComponentManager/HoloComponentManager";
import VideoManager from "./components/Tools/VideoManager/VideoManager";
import CreatePage from "./components/Common/CreatePage/CreatePage";
import ProfilePage from "./components/Common/ProfilePage/ProfilePage";
import BrowserPage from "./components/BrowserPage/BrowserPage";
import useAuth from "./useAuth";
import DemoPage from "./components/DemoPage/DemoPage";
import HomePage from "./components/HomePage/HomePage";
import { Pages } from "./constants";
import { Alert } from "@mui/material";
import SignInPage from "./components/SignInPage/SignInPage";
import LoadingPanel from "./components/Common/LoadingPanel/LoadingPanel";

function HoloPageById({ preview }) {
  const [page, setPage] = React.useState(null);
  const { id } = useParams();

  React.useEffect(() => {
    !page && getHoloPage(id).then((p) => setPage(p.Item));
  }, [page, id]);

  if (!page) return <LoadingPanel>Loading your scene info</LoadingPanel>;

  return <HoloPage config={page} preview={preview} />;
}

function EditPageById() {
  const { id } = useParams();
  return <EditorPage id={id} />;
}

const message =
  "You can only add 3 holograms to ascene. Remove some items and try again.";

function HoloBrowser() {
  const navigate = useNavigate();
  const auth = useAuth();
  const verify = (count) => count < 3 || auth.isAdmin();
  const title = auth.username ? "Create a hologram scene" : "Create a hologram";
  const primary = auth.username
    ? "Select any video or widget to create a new scene"
    : "Select any video or widget to create a one-click hologram";
  return (
    <BrowserPage
      verify={verify}
      message={message}
      title={title}
      primary={primary}
      submit={(type, time, degrees) =>
        navigate(`/new/${type}/${time}/${degrees}`)
      }
    />
  );
}

function SearchPage() {
  const { param } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const verify = (count) => count < 3 || auth.isAdmin();
  return (
    <BrowserPage
      param={param}
      verify={verify}
      message={message}
      title={`Holograms with "${param}" in the name`}
      primary="Select any video or widget to create a new scene"
      submit={(type, time, degrees) =>
        navigate(`/new/${type}/${time}/${degrees}`)
      }
      cancel={() => navigate(Pages.BROWSER_PAGE)}
    />
  );
}

function NewHologram() {
  const { type, time, degrees } = useParams();
  return <CreatePage type={type} time={time} degrees={degrees} />;
}

function DemoHolo() {
  const { type } = useParams();
  return <DemoPage type={type} />;
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={Pages.BROWSER_PAGE} element={<HoloBrowser />} />
          <Route path="/view" element={<HoloPage config={HoloConfig} />} />
          <Route path="/admin" element={<HoloComponentManager />} />
          <Route path="/demo/:type" element={<DemoHolo />} />
          <Route path="/new/:type/:time" element={<NewHologram />} />
          <Route path="/new/:type/:time/:degrees" element={<NewHologram />} />
          <Route path="/find/:param" element={<SearchPage />} />
          <Route path="/new" element={<CreatePage />} />
          <Route path="/signup" element={<SignInPage initialMode="signup" />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/video" element={<VideoManager />} />
          <Route path="/render/:id" element={<HoloPageById />} />
          <Route path="/view/:id" element={<HoloPageById />} />
          <Route path="/preview/:id" element={<HoloPageById preview />} />
          <Route path="/edit/:id/:dummy" element={<EditPageById />} />
          <Route path="/edit/:id" element={<EditPageById />} />
          <Route path="/edit" element={<EditorPage />} />
          <Route path={Pages.HOME_PAGE} element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
